import { useEffect, useState } from "react";
import { Translate } from "./sdk/translate";
import { Button, Input } from "antd";
import { useHistory, useParams } from "react-router";
import GenericPopup from "./components/GenericPopup";
import { messageType } from "./sdk/utils/types";

// Assets
import { LOGO, LOGIN_IMAGE_2 } from "./assets/exports";
import { UserAPI } from "./sdk/api/endpoints";

const ResetPwd = () => {
    const history = useHistory();
    const params = useParams<{ token: string }>();

    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<[string, messageType]>(['', 'info']);
    const [password, setPassword] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');

    useEffect(() => {
        if (!params.token) {
            history.replace('/login')
        }
    }, [params])

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                if (enableButton()) {
                    submit()
                }
                else
                    setMessage(['Bitte fülle alle Felder aus', 'error']);
            }
        };

        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [password, password2, enableButton])

    function enableButton(): boolean {
        return password.trim() !== '' && password2.trim() !== '' && password == password2
    }

    function submit() {
        setLoading(true)
        UserAPI.resetPassword(params.token, password).then(() => {
            history.replace('/')
        }).catch((err) => {
            setMessage([Translate.GeneralError, 'error'])
            setLoading(false)
        })
    }

    return (
        <>
            <div className="reset-pwd-container">
                <div style={{ backgroundImage: `url(${LOGIN_IMAGE_2})` }} className="image">
                    <img className="logo" src={LOGO} alt="" />
                </div>
                <div className="right-side">
                    <div className="header">{Translate.AssignNewPassword}</div>
                    <Input.Password className="input" value={password} onChange={(event) => setPassword(event.target.value)} placeholder={Translate.Password} />
                    <Input.Password className="input" value={password2} onChange={(event) => setPassword2(event.target.value)} placeholder={Translate.RepeatPassword} />
                    <div className="forgot-password-container">
                        <div onClick={() => history.goBack()} className="forgot-password">{Translate.BackToLogin}</div>
                    </div>
                    <Button onClick={() => submit()} type="primary" disabled={!enableButton()} loading={loading} style={{
                        backgroundColor: enableButton() ? '#9FC0A3' : '#D7D7D7',
                        cursor: enableButton() ? 'pointer' : 'default'
                    }} className="login-button">{Translate.Next}</Button>
                </div>
                <GenericPopup show={message[0] !== ''} setMessageState={setMessage} message={message} />
            </div>
        </>
    );
}

export default ResetPwd;