import * as React from 'react'
import { LOADER } from '../assets/exports';

export class FitjoLoader extends React.Component<{}> {

    render() {

        return (
            <img className="fitjo-loader" src={LOADER} alt='' />
        )
    }
}