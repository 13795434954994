import { Route, Switch, useHistory } from "react-router";
import { API as DiscountAPI } from '../sdk/api/endpoints/discount';
import { Translate } from "../sdk/translate";
import { useEffect, useState } from "react";
import { Discount } from "../sdk/api/models/discount";

// Assets
import { ARROW_LEFT, NEWS_IMAGE_1 } from "../assets/exports";
import { FitjoLoader } from "../components/FitjoLoader";
import DiscountsSingle from "./DiscountsSingle";

const Discounts = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true)
    const [discounts, setDiscounts] = useState<Array<Discount>>([])

    useEffect(() => {
        DiscountAPI.getDiscounts().then((discountArr) => {
            if (discountArr.length !== 0) {
                setDiscounts(discountArr)
            }
        }).catch((err) => {
            console.log('Error', err)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <Switch>
            <Route path='/fitjo/discounts/:id' component={() => <DiscountsSingle {...props} discounts={discounts} />} />
            <Route render={() => {
                return (
                    <>
                        {
                            !loading ?
                                <div className="discounts-container">
                                    <div className="header">
                                        <>{Translate.Discounts}</>
                                        <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                                    </div>
                                    <div className="row">
                                        {
                                            discounts.length !== 0 ?
                                                discounts.map((discount, key) => {
                                                    return (
                                                        <div key={key} onClick={() => history.push('/fitjo/discounts/' + discount.id)} style={{ backgroundImage: `url(${discount.img_url})` }} className="element"><span className="element-text">{discount.name}</span></div>
                                                    )
                                                })
                                                :
                                                <div className="element"><span className="element-text">{Translate.NoDiscounts}</span></div>
                                        }
                                    </div>
                                </div>
                                :
                                <div className="loading-page">
                                    <div className="loader-container">
                                        <FitjoLoader />
                                    </div>
                                </div>
                        }
                    </>
                )
            }} />
        </Switch>
    )
}

export default Discounts;