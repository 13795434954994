import { News } from "../models/news";
import { NEWS_TYPE } from "../../utils/types";
import { APIVersion, AuthenticatedRequest, HTTPMethod } from "../requests/request"

export class API {

    /**
     * Get the news
     */
    public static getNews(type?: NEWS_TYPE, search?: string): Promise<News[]> {
        let apiReqest = new AuthenticatedRequest<News>('/news', HTTPMethod.GET, APIVersion.v1_0, {
            type,
            search
        });
        return apiReqest.send(News).then((response) => {
            return <News[]>response.data
        })
    }

    /**
     * Add a news Article to favorites
     * 
     * @param newsId 
     */
    public static addFavorite(newsId: string): Promise<any> {
        let apiReqest = new AuthenticatedRequest<any>(`/news/${newsId}/favorite/add`, HTTPMethod.POST, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Remove a news Article from favorites
     * 
     * @param newsId 
     */
    public static removeFavorite(newsId: string): Promise<any> {
        let apiReqest = new AuthenticatedRequest<any>(`/news/${newsId}/favorite/remove`, HTTPMethod.POST, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Get the News Favorites
     */
    public static getFavorites(): Promise<News[]> {
        let apiReqest = new AuthenticatedRequest<News[]>('/news/favorites', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }
}