import { useHistory } from "react-router";
import { Translate } from "../sdk/translate";

// Assets
import {
    FAVORITES_IMAGE_1,
    FAVORITES_IMAGE_2,
    FAVORITES_IMAGE_3,
} from '../assets/exports';

const Favorites = () => {
    const history = useHistory();

    return (
        <>
            <div className="favorites-container">
                <div className="header">{Translate.Favorites}</div>
                <div className="info-text">{Translate.FavoritesInfo}</div>
                <div className="row">
                    <div onClick={() => history.push('/favorites/training')} style={{ backgroundImage: `url(${FAVORITES_IMAGE_1})` }} className="element"><span className="element-text">{Translate.Training}</span></div>
                    <div onClick={() => history.push('/favorites/recipes')} style={{ backgroundImage: `url(${FAVORITES_IMAGE_2})` }} className="element"><span className="element-text">{Translate.Nutrition}</span></div>
                    {/* <div onClick={() => history.push('/favorites/category')} style={{ backgroundImage: `url(${FAVORITES_IMAGE_3})` }} className="element"><span className="element-text">{Translate.Meditation}</span></div> */}
                    <div onClick={() => history.push('/favorites/news')} className="element"><span className="element-text">{Translate.News}</span></div>
                </div>
            </div>
        </>
    );
}

export default Favorites;