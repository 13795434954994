import { News } from "../models/news";
import { Recipe, RecipeType } from "../models/recipe";
import { APIVersion, AuthenticatedRequest, HTTPMethod } from "../requests/request"

export class API {

    /**
     * Get the recipes
     */
    public static getRecipes(type?: RecipeType): Promise<Recipe[]> {
        let apiReqest = new AuthenticatedRequest<Recipe>('/recipes', HTTPMethod.GET, APIVersion.v1_0, {
            type
        });
        return apiReqest.send(Recipe).then((response) => {
            return <Recipe[]>response.data
        })
    }

    /**
     * Add a Recipe to favorites
     * 
     * @param recipeId 
     */
    public static addFavorite(recipeId: string): Promise<any> {
        let apiReqest = new AuthenticatedRequest<any>(`/recipes/${recipeId}/favorite/add`, HTTPMethod.POST, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Remove a Recipe from favorites
     * 
     * @param recipeId 
     */
    public static removeFavorite(recipeId: string): Promise<any> {
        let apiReqest = new AuthenticatedRequest<any>(`/recipes/${recipeId}/favorite/remove`, HTTPMethod.POST, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

        /**
     * Get the Recipe Favorites
     */
    public static getFavorites(): Promise<Recipe[]> {
        let apiReqest = new AuthenticatedRequest<Recipe[]>('/recipes/favorites', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }
}