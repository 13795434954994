import { BaseModel } from "./base";
import { JsonProperty } from "../requests/jsonparser";
import { User } from "./user";

export class Device extends BaseModel {

    @JsonProperty({ type: 'string?' })
    session_token?: string

    @JsonProperty({ type: 'boolean' })
    debug: boolean

    @JsonProperty({ type: 'boolean' })
    valid: boolean

    @JsonProperty({ type: 'string' })
    device_uuid: string

    @JsonProperty({ type: 'string' })
    app_version: string

    @JsonProperty({ type: 'string' })
    build_version: string

    @JsonProperty({ type: 'string?' })
    user_id?: string

    @JsonProperty({ type: 'string' })
    language: string

    @JsonProperty({ type: 'string?' })
    token_provider?: string

    @JsonProperty({ type: 'string?' })
    device_data_os?: string;

    @JsonProperty({ type: 'string?' })
    device_data_os_version?: string;

    @JsonProperty({ type: 'string?' })
    device_data_device_type?: string;

    @JsonProperty({ type: 'string?' })
    device_data_device_name?: string;

    @JsonProperty({ type: 'string?' })
    device_data_device_category?: string;

    @JsonProperty({ type: 'string?' })
    device_data_carrier?: string;

    @JsonProperty({ type: 'string?' })
    device_data_battery?: string;

    @JsonProperty({ clazz: User, type: 'User' })
    User?: User;

    @JsonProperty({ type: 'string?' })
    sns_endpoint?: string | undefined;

    @JsonProperty({ type: 'number?' })
    badge?: number;
}