import { Device } from '../models/device';
import { APIVersion, BasicRequest, HTTPMethod } from '../requests/request';
import { DeviceData } from './device';
export class API {

    /**
     * Login
     * 
     * @param email email
     * @param password password
     * @param device Device Data
     */
    public static login(email: string, password: string, device: DeviceData): Promise<Device> {
        let apiReqest = new BasicRequest<Device>('/login', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            email,
            password,
            device
        });
        return apiReqest.send(Device).then((response) => {
            return response.data
        })
    }

    /**
     * Register a new user
     * 
     * @param inviteKey 
     * @param email 
     * @param password 
     * @param name 
     * @param device 
     */
    public static register(inviteKey: string, email: string, password: string, name: string, device: DeviceData): Promise<Device> {
        let apiReqest = new BasicRequest<Device>('/users', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            registration_data: {
                email,
                password,
            },
            registration_fullname: name,
            invite_key: inviteKey,
            device
        });
        return apiReqest.send(Device).then((response) => {
            return response.data
        })
    }
}