import { useState, useEffect } from "react";
import { API as TrainingAPI } from '../sdk/api/endpoints/training';
import { API as RecipesAPI } from '../sdk/api/endpoints/recipes';
import { API as NewsAPI } from '../sdk/api/endpoints/news';

// Assets
import {
    BOOKMARK,
    BOOKMARK_FILLED,
} from '../assets/exports';
import { FitjoLoader } from "./FitjoLoader";

interface FavoritesBoxProps {
    type: string
    key?: number
    title: string
    id: string
    backgroundImage?: string
    onClick: () => void
    onStateChange: (val: boolean, title: string) => void
}

const FavoritesBox = (props: FavoritesBoxProps) => {
    const [isFavorite, setFavorite] = useState<boolean>(true);
    const [ignoreFirst, setIgnoreFirst] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)

    const handleFav = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        e.stopPropagation()
        setLoading(true)
        switch (props.type) {
            case 'training':
                trainingAPICall()
                break
            case 'recipes':
                recipeAPICall()
                break
            case 'news':
                newsAPICall()
                break
        }
    }

    useEffect(() => {
        ignoreFirst ? props.onStateChange(isFavorite, props.title) : setIgnoreFirst(true)
        // eslint-disable-next-line
    }, [isFavorite])

    const trainingAPICall = () => {
        if (isFavorite) {
            TrainingAPI.deleteFavoriteBusinessTraining(props.id).then(() => {
                setFavorite(false);
                setLoading(false);
            }).catch((err) => {
                console.log('Error', err)
                setLoading(false);
            })
        }
        else {
            setLoading(false)
        }
    }

    const recipeAPICall = () => {
        if (isFavorite) {
            RecipesAPI.removeFavorite(props.id).then(() => {
                setFavorite(false);
                setLoading(false);
            }).catch((err) => {
                console.log('Error', err)
                setLoading(false);
            })
        }
        else {
            RecipesAPI.addFavorite(props.id).then(() => {
                setFavorite(true);
                setLoading(false);
            }).catch((err) => {
                console.log('Error', err)
                setLoading(false);
            })
        }
    }

    const newsAPICall = () => {
        if (isFavorite) {
            NewsAPI.removeFavorite(props.id).then(() => {
                setFavorite(false);
                setLoading(false);
            }).catch((err) => {
                console.log('Error', err)
                setLoading(false);
            })
        }
        else {
            NewsAPI.addFavorite(props.id).then(() => {
                setFavorite(true);
                setLoading(false);
            }).catch((err) => {
                console.log('Error', err)
                setLoading(false);
            })
        }
    }

    return (
        <>
            <div key={props.key} onClick={() => {
                if (props.type !== 'training' || (props.type === 'training' && isFavorite))
                    props.onClick()
            }} style={{ backgroundImage: `url(${props.backgroundImage})` }} className="favorites-box-container">
                <span className="element-text">{props.title}</span>

                <div className="bookmark-container">
                    {
                        !loading ?
                            <img onClick={(e) => handleFav(e)} className="bookmark" src={isFavorite ? BOOKMARK_FILLED : BOOKMARK} alt="" />
                            :
                            <FitjoLoader />
                    }
                </div>
            </div>
        </>
    )
}

export default FavoritesBox;