import { RouteComponentProps, useHistory, useParams } from 'react-router';
import { Translate } from '../sdk/translate';
import moment from 'moment';
import { Button, Calendar, DatePicker, Switch, TimePicker } from 'antd';
import { useEffect, useState } from 'react';
import { Util } from '../sdk/utils/util';
import { MONTHS, WEEK_DAYS_FULL } from '../sdk/utils/constants';
import locale from 'antd/es/date-picker/locale/de_DE';
import { CalendarEvent, EventType } from '../sdk/api/models/calendarEvent';
import { FitjoLoader } from '../components/FitjoLoader';
import { API as CalendarAPI } from '../sdk/api/endpoints/calendar';

// Assets
import { ARROW_LEFT, CALENDAR_EDIT_ICON, TIMES } from '../assets/exports';
import { start } from 'repl';
import { messageType } from '../sdk/utils/types';
import GenericPopup from '../components/GenericPopup';


type PopupState = 'first' | 'second'

type Props = {
    events: Array<CalendarEvent>
};
type CalendarMonthProps = Props & RouteComponentProps<{
    month: string
    year: string
}>;


const CalendarMonth = (props: CalendarMonthProps) => {
    const location = useParams<{ month, year }>();
    const history = useHistory();

    //Popup
    const [currentPopup, setCurrentPopup] = useState<PopupState>('first');
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [type, setType] = useState<EventType>('meditation');
    const [startDate, setStartDate] = useState<moment.Moment>(moment());
    const [endDate, setEndDate] = useState<moment.Moment>(moment().add(30, 'minutes'));
    const [startTime, setStartTime] = useState<moment.Moment>(moment().hours(10).minutes(0).seconds(0));
    const [endTime, setEndTime] = useState<moment.Moment>(moment().hours(10).minutes(30).seconds(0));
    const [eventList, setEventList] = useState<Array<CalendarEvent>>([])
    const [loadingEventList, setLoadingEventList] = useState(true)
    const [showReminders, setShowReminders] = useState<boolean>(false);
    const [reminder, setReminder] = useState<Array<[number, boolean, string]>>([[5, false, '5 Minuten vorher'], [10, false, '10 Minuten vorher'], [15, false, '15 Minuten vorher'], [30, false, '30 Minuten vorher'], [60, false, '1 Stunde vorher'], [120, false, '2 Stunden vorher'], [1440, false, '1 Tag vorher'], [2880, false, '2 Tage vorher']]);
    const [loadingSubmitEvent, setloadingSubmitEvent] = useState(false)
    const [message, setMessage] = useState<[string, messageType]>(['', 'info'])
    const [edit, setEdit] = useState(false)
    const [id, setId] = useState<string>('')
    const [calendarRerender, setCalenderRerender] = useState(0)
    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false)
    const [loadDelete, setLoadDelete] = useState(false)

    const openDialog = (edit: boolean, event?: CalendarEvent) => {
        setEdit(edit)
        if (edit) {
            setId(event!.id!)
            setStartDate(moment(event?.start_date))
            setEndDate(moment(event?.end_date))
            setStartTime(moment(event?.start_date))
            setEndTime(moment(event?.end_date))
            setCurrentPopup('second')
            setShowPopup(true)
        }
        else {
            setShowPopup(true)
        }
    }

    const openConfirmDelete = (event: CalendarEvent) => {
        setId(event.id!)
        setShowConfirmDeletePopup(true)
    }

    const handleDelete = () => {
        setLoadDelete(true)
        CalendarAPI.deleteCalendarEvent(id).then(() => {
            const neventList = eventList.filter((event) => event.id !== id)
            setEventList(neventList)
            setCalenderRerender(calendarRerender + 1);
            setMessage(['Event erfolgreich gelöscht', 'success'])
        }).catch((err) => {
            console.log('Error', err)
        }).finally(() => {
            setLoadDelete(false)
            setShowConfirmDeletePopup(false)
            setId('')
        })
    }

    useEffect(() => {
        setEventList(props.events.filter((event) => {
            let currentStart = moment(event.start_date)
            if (Util.momentInRange(currentStart, currentSelectableRange)) {
                return true
            }
            return false
        }))
        setLoadingEventList(false)
    }, [props.events])

    const getMonthAsNumber = () => {
        for (let i = 0; i < MONTHS.length; i++) {
            if (MONTHS[i] === location.month) {
                return i;
            }
        }
        return -1;
    }

    const before = moment([location.year, getMonthAsNumber()]).subtract(1, 'month');
    const after = moment([location.year, getMonthAsNumber()]).add(1, 'month');
    const currentSelectableRange: [moment.Moment, moment.Moment] = [moment([location.year, getMonthAsNumber()]), moment([location.year, getMonthAsNumber()]).endOf('month')];
    const [selectedDate, setSelectedDate] = useState<moment.Moment>(currentSelectableRange[0]);
    const fixedStart = moment().startOf('month');
    const fixedEnd = moment().add('3', 'months').endOf('month');

    const onSelect = (value) => {
        setSelectedDate(value);
        setStartDate(value);
        setEndDate(value);
        if (Util.momentInRange(value, currentSelectableRange))
            return;
        else {
            history.push('/profile/calendar/' + value.year() + "/" + MONTHS[value.month()]);
        }
    }

    const switchCalendar = (direction: string) => {
        switch (direction) {
            case 'backwards':
                setSelectedDate(before);
                history.push('/profile/calendar/' + before.year() + "/" + MONTHS[before.month()]);
                break;
            case 'forwards':
                setSelectedDate(after);
                history.push('/profile/calendar/' + after.year() + "/" + MONTHS[after.month()]);
                break;
        }
    }

    function dateCellRender(value: moment.Moment) {
        let eventFound = false
        eventList.every((event) => {
            let start = moment(event.start_date)

            if (start.isSame(value, 'day')) {
                eventFound = true
                return false
            }
            return true
        })
        if (eventFound)
            return (
                <div className="dot">•</div>
            );
    }

    const handleTypeClick = (type: EventType) => {
        setType(type);
        setCurrentPopup('second');
    }

    const closePopup = () => {
        setCurrentPopup('first');
        setShowPopup(false);
    }

    const handleSubmit = () => {
        setloadingSubmitEvent(true)
        let submitStartMoment = moment(startDate).hour(startTime.hours()).minute(startTime.minutes()).seconds(0)
        let submitEndMoment = moment(endDate).hour(endTime.hours()).minute(endTime.minutes()).seconds(0)
        let submitReminders: Array<number> = []
        reminder.forEach((reminderValue) => {
            if (reminderValue[1]) {
                submitReminders.push(reminderValue[0])
            }
        })
        if (!edit) {
            CalendarAPI.addCalendarEvent({
                start_date: submitStartMoment.toDate(),
                end_date: submitEndMoment.toDate(),
                type: type,
                reminder: submitReminders
            }).then((newEvent) => {
                eventList.push(newEvent)
                setEventList(eventList.slice())
                setCalenderRerender(calendarRerender + 1)
                closePopup();
            }).catch((err) => {
                console.log('Error', err)
                setMessage([Translate.GeneralError, 'error'])
            }).then(() => {
                setloadingSubmitEvent(false)
            })
        }
        else {
            CalendarAPI.updateCalendarEvent(id, {
                start_date: submitStartMoment.toDate(),
                end_date: submitEndMoment.toDate(),
                type: type,
                reminder: submitReminders
            }).then((updated) => {
                const neventList = (eventList.filter((event) => event.id !== updated.id))
                neventList.push(updated)
                setEventList(neventList)
                setCalenderRerender(calendarRerender + 1)
                closePopup();
            }).catch((err) => {
                console.log('Error', err)
                setMessage([Translate.GeneralError, 'error'])
            }).then(() => {
                setloadingSubmitEvent(false)
            })
        }
    }

    const toggleReminder = (key: number) => {
        let arr = reminder;
        for (let i = 0; i < arr.length; i++) {
            if (i === key) {
                arr[i][1] = !arr[i][1];
                break;
            }
        }
        setReminder(arr => [...arr]);
    }
    const handleEndTimeChange = (time) => {
        setEndTime(time);
        if (time.valueOf() < startTime.valueOf() && startDate.date() === endDate.date()) {
            setStartTime(time);
        }
    }

    const handleEndDateChange = (time: moment.Moment | null, dateString: string) => {

        if (Util.momentInRange(time!, [fixedStart, fixedEnd])) {
            setEndDate(time!);
            if (time!.valueOf() < startDate.valueOf()) {
                setStartDate(time!)
            }
        }
        else {
            setEndTime(moment())
        }

        if (time!.date() === startDate.date() && startTime.valueOf() > endTime.valueOf()) {
            setEndTime(startTime)
        }
    }
    const handleStartTimeChange = (time) => {
        setStartTime(time)
        if (time.valueOf() > endTime.valueOf() && startDate.date() === endDate.date()) {
            setEndTime(time)
        }
    }
    const handleStartDateChange = (time) => {
        if (Util.momentInRange(time, [fixedStart, fixedEnd])) {
            setStartDate(time)
            if (time!.valueOf() > endDate.valueOf()) {
                setEndDate(time!)
            }
        }
        else {
            setStartTime(moment())
        }

        if (endDate.date() === time.date() && startTime.valueOf() > endTime.valueOf()) {
            setEndTime(startTime)
        }
    }

    const compareEvents = (a: CalendarEvent, b: CalendarEvent) => {
        let aMoment: moment.Moment = moment(a.start_date)
        let bMoment: moment.Moment = moment(b.start_date)
        if (aMoment.valueOf() < bMoment.valueOf())
            return -1
        else if (aMoment.valueOf() > bMoment.valueOf())
            return 1
        else
            return 0
    }

    const getTrainingType = (type: EventType) => {
        switch (type) {
            case 'meditation':
                return Translate.Meditation
            case 'training_business': 
                return Translate.Training + " " + Translate.Business
            case 'training_home':
                return Translate.Training + " " + Translate.Home
        }
    }

    return (
        <>
            <div className="calendar-month-container">
                <div className="header-wrapper">
                    <div className="header">
                        <>{location.month + ", " + location.year}</>
                        <img onClick={() => history.push('/profile/calendar')} className="arrow-left" src={ARROW_LEFT} alt="" />
                    </div>
                    <div onClick={() => openDialog(false)} className="add-button">+</div>
                </div>
                <div className="calendar-row">
                    <div className="single-calendar">
                        <div className="calendar-header-container">
                            <div className="button-row">
                                <span style={{ display: before.valueOf() < fixedStart.valueOf() ? "none" : "block" }} onClick={() => switchCalendar('backwards')} className="button">&#x2039;</span><span style={{ display: after.valueOf() > fixedEnd.valueOf() ? "none" : "block" }} onClick={() => switchCalendar('forwards')} className="button">&#x203A;</span>
                            </div>
                        </div>
                        <Calendar key={calendarRerender} dateCellRender={dateCellRender} validRange={[fixedStart, fixedEnd]} onSelect={onSelect} value={selectedDate} defaultValue={currentSelectableRange[0]} fullscreen={false} />
                    </div>
                    <div className="grid-container">
                        {
                            !loadingEventList ?
                                eventList.sort(compareEvents).map((event, key) => {
                                    return (
                                        <div key={key} className='grid'>
                                            <div className="day">{moment(event.start_date).format('D')}<br />{location.month.substr(0, 3)}</div>
                                            <div className="event-container">
                                                <div className="event">
                                                    <div className="type">{getTrainingType(event.type)}</div>
                                                    <div className="time">{moment(event.start_date).format('HH:mm')}</div>
                                                </div>
                                            </div>
                                            <div className='icons-container'>
                                                <img onClick={() => openDialog(true, event)} src={CALENDAR_EDIT_ICON} alt='' />
                                                <img onClick={() => openConfirmDelete(event)} src={TIMES} alt='' />
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className='loader-container'>
                                    <FitjoLoader />
                                </div>
                        }
                    </div>
                </div>
                <div style={{ display: showPopup ? 'flex' : 'none' }} className="wholepage-container">
                    <div className="popup-container">
                        <img onClick={() => closePopup()} className="close-button" src={TIMES} alt="" />
                        <div className="headline">{edit ? Translate.EditEvent : Translate.AddEevnt}</div>
                        <div style={{ display: currentPopup === 'first' ? 'flex' : 'none' }} className="popup-content">
                            <div onClick={() => handleTypeClick('training_business')} className="row margin-bottom">
                                <div className="type">{Translate.Training} {Translate.Business}</div>
                                <img className="arrow" src={ARROW_LEFT} alt="" />
                            </div>
                            <div onClick={() => handleTypeClick('training_home')} className="row">
                                <div className="type">{Translate.Training} {Translate.Home}</div>
                                <img className="arrow" src={ARROW_LEFT} alt="" />
                            </div>
                            <div onClick={() => handleTypeClick('meditation')} className="row">
                                <div className="type">{Translate.Meditation}</div>
                                <img className="arrow" src={ARROW_LEFT} alt="" />
                            </div>
                        </div>
                        <div style={{ display: currentPopup === 'second' ? 'flex' : 'none' }} className="popup-content">
                            <div className="pickdate-container">
                                <div className="which">{Translate.Start}:</div>
                                <div className="date">{WEEK_DAYS_FULL[startDate.day()]}, {startDate.date()}. {MONTHS[startDate.month()]}<DatePicker value={startDate} onChange={handleStartDateChange} className="date-picker" locale={locale} format='MMMM, D. dddd' /></div>
                                <div className="time"><TimePicker value={startTime} onChange={handleStartTimeChange} bordered={false} defaultValue={moment('10:00', 'HH:mm')} format={'HH:mm'} /></div>
                            </div>
                            <div className="pickdate-container">
                                <div className="which">{Translate.End}:</div>
                                <div className="date">{WEEK_DAYS_FULL[endDate.day()]}, {endDate.date()}. {MONTHS[endDate.month()]}<DatePicker value={endDate} onChange={handleEndDateChange} className="date-picker" locale={locale} format='MMMM, D. dddd' /></div>
                                <div className="time"><TimePicker value={endTime} onChange={handleEndTimeChange} bordered={false} defaultValue={moment('10:30', 'HH:mm')} format={'HH:mm'} /></div>
                            </div>
                            <div className="pickdate-container">
                                <>&#160;</>
                                <div className="reminder">{Translate.Reminder}</div>
                                <div className="switch-container">
                                    <Switch checked={showReminders} onClick={() => setShowReminders(!showReminders)} className="" />
                                </div>
                            </div>
                            <div style={{ display: showReminders ? 'grid' : 'none' }} className="pickdate-container">
                                <>&#160;</>
                                <div className="reminder-container">
                                    {reminder.map((value, key) => {
                                        return (
                                            <div style={{ color: value[1] ? '#9FC0A3' : '' }} onClick={() => toggleReminder(key)} key={key} className="single-reminder">{value[2]}</div>
                                        )
                                    })}
                                </div>
                            </div>

                        </div>
                        <div className="submit-button-container">
                            <Button loading={loadingSubmitEvent} onClick={() => handleSubmit()} className="submit-button">
                                {Translate.Save}
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{ display: showConfirmDeletePopup ? 'flex' : 'none' }} className="wholepage-container">
                    <div className="popup-container">
                        <img onClick={() => setShowConfirmDeletePopup(false)} className="close-button" src={TIMES} alt="" />
                        <div className="headline">Event löschen?</div>
                        <div style={{ display: 'flex' }} className="popup-content">
                            <div style={{ borderBottom: 'none', cursor: 'default', justifyContent: 'center' }} className="row">
                                <Button loading={loadDelete} onClick={() => handleDelete()} className="delete-button">Ja, Event löschen</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <GenericPopup show={message[0] !== ''} message={message} setMessageState={setMessage} />
            </div>
        </>
    )
}

export default CalendarMonth;