import {JsonProperty} from "../requests/jsonparser";

export class BaseModel {

    @JsonProperty({ required: false, type: 'string?' })
    id?: string;

    @JsonProperty({ required: false, type: 'string?' })
    created_at?: string;

    @JsonProperty({ required: false, type: 'string?' })
    updated_at?: string;

    @JsonProperty({ required: false, type: 'string?' })
    deleted_at?: string;

}