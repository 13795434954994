import { Translate } from "../sdk/translate";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import { CompanyEvent } from "../sdk/api/models/companyEvent";

// Assets
import {
    ARROW_LEFT,
    EVENTS_IMAGE_1,
} from "../assets/exports";
import { useEffect, useState } from "react";
import { FitjoLoader } from "../components/FitjoLoader";

type Props = {
    events: Array<CompanyEvent>
};
type EventsSingleProps = Props & RouteComponentProps<{}>;

const EventsSingle = (props: EventsSingleProps) => {
    const history = useHistory()
    const params = useParams<{ id }>()
    const [event, setEvent] = useState<CompanyEvent>()
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        let eventFound = false
        props.events.every((event) => {
            if (event.id === params.id) {
                eventFound = true
                setEvent(event)
                setLoading(false)
                return false
            }
            return true
        })

        if (!eventFound) {
            history.push('/my-company/events/')
        }
    }, [])

    return (
        <>
            <div className="events-single-container">
                {
                    !loading ?
                        <>
                            <div className="image" style={{ backgroundImage: `url(${event?.img_url})` }} />
                            <div className="content-container">
                                <div className="header">
                                    <>{Translate.Events}</>
                                    <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                                </div>
                                <div className="event-name">{event?.name}</div>
                                <div className="event-text">{event?.description}</div>
                            </div>
                        </>
                        :
                        <div className="loading-page">
                            <div className="loader-container">
                                <FitjoLoader />
                            </div>
                        </div>
                }
            </div>
        </>
    );
}

export default EventsSingle;