import { RouteComponentProps, useHistory, useParams } from "react-router";
import { Discount } from "../sdk/api/models/discount";
import { Translate } from "../sdk/translate";

// Assets
import {
    ARROW_LEFT,
    DISCOUNTS_IMAGE_1,
} from "../assets/exports";
import { useEffect, useState } from "react";

type Props = {
    discounts: Array<Discount>
};
type DiscountsSingleProps = Props & RouteComponentProps<{}>;

const DiscountsSingle = (props: DiscountsSingleProps) => {
    const history = useHistory();
    const params = useParams<{ id }>()
    const [loading, setLoading] = useState<boolean>(true)
    const [discount, setDiscount] = useState<Discount>()

    useEffect(() => {
        props.discounts.every((currentDiscount) => {
            if (currentDiscount.id === params.id) {
                setDiscount(currentDiscount)
                setLoading(false)
                return false
            }
            return true
        })
    }, [])

    return (
        <>
            <div className="discounts-single-container">
                <div className="image" style={{ backgroundImage: `url(${discount?.img_url})` }} />
                <div className="content-container">
                    <div className="header">
                        <>{discount?.name}</>
                        <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                    </div>
                    <div className="text">{discount?.description}</div>
                    <a href={discount?.url} className="button">{discount?.url}</a>
                    <div className="code">{Translate.DiscountCode}: {discount?.code}</div>
                </div>
            </div>
        </>
    );
}

export default DiscountsSingle;