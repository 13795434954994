import { Translate } from "../sdk/translate";
import { Route, Switch, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { API as CompanyAPI } from '../sdk/api/endpoints/company';

// Assets
import {
    ARROW_LEFT, MEDITATION_IMAGE_1,
} from "../assets/exports";
import { CompanyEvent } from "../sdk/api/models/companyEvent";
import { setCommentRange } from "typescript";
import { FitjoLoader } from "../components/FitjoLoader";
import EventsSingle from "./EventsSingle";

const Events = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true)
    const [events, setEvents] = useState<Array<CompanyEvent>>([])

    useEffect(() => {
        CompanyAPI.getCompanyEvents().then((companyEvents) => {
            if (companyEvents.length !== 0) {
                setEvents(companyEvents)
            }
        }).catch((err) => {
            console.log('Error', err)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <>
            <Switch>
                <Route path='/my-company/events/:id' component={() => <EventsSingle {...props} events={events} />} />
                <Route render={() => {
                    return (
                        <div className="events-container">
                            {
                                !loading ?
                                    <div className="content-container">
                                        <div className="header">
                                            <>{Translate.Events}</>
                                            <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                                        </div>
                                        <div className="row">
                                            {
                                                events.length !== 0 ?
                                                    events.map((event, key) => {
                                                        return (
                                                            <div key={key} onClick={() => history.push('/my-company/events/' + event.id)} style={{ backgroundImage: `url(${event.img_url})` }} className="element"><span className="element-text">{event.name}</span></div>
                                                        )
                                                    })
                                                    :
                                                    <div className="element"><span className="element-text">{Translate.NoEvents}</span></div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="loading-page">
                                        <div className="loader-container">
                                            <FitjoLoader />
                                        </div>
                                    </div>
                            }
                        </div>

                    )
                }} />
            </Switch>
        </>
    );
}

export default Events;