import { ReminderUnit } from "./types";
import { Translate } from "../translate";

export const USER_KEY = 'fitjo-user-session-key';
export const TUTORIAL_KEY = 'fitjo-tutorial-key';


export const TrainingTypeEnum = {
    BUSINESS_STANDING: 'business_standing',
    BUSINESS_SEDENTARY_DESK: 'business_sedentary_desk',
    BUSINESS_SEDENTARY_CAR: 'business_sedentary_car',
    HOME_WHOLE_BODY: 'home_whole_body',
    HOME_WHOLE_BACKFIT: 'home_backfit',
    HOME_WHOLE_MOBILITY: 'home_mobility',
    HOME_WHOLE_HIIT: 'home_hiit',
    HOME_WHOLE_ALB: 'home_alb',
    HOME_UPPER_BODY: 'home_upper_body',
    MEDITATION: 'meditation'
}

export const WEEK_DAYS = ['M', 'D', 'M', 'D', 'F', 'S', 'S']
export const WEEK_DAYS_FULL = ['Sontag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sontag'];
export const MONTHS = [Translate.Jan_Full, Translate.Feb_Full, Translate.Mar_Full, Translate.Apr_Full, Translate.Mai_Full, Translate.Jun_Full, Translate.Jul_Full, Translate.Aug_Full, Translate.Sep_Full, Translate.Oct_Full, Translate.Nov_Full, Translate.Dec_Full];

export interface NewsItem {
    headline: string,
    subhead: string,
}

export interface IReminder {
    id: string
    val: number
    unit: ReminderUnit
}

export const STORED_LOCALLY_DATA_KEYS = {
    GFIT_AUTHORIZED: '@GFit_autorized',
    GFIT_DISABLED: '@GFit_disabled'
}

export const REMINDER: IReminder[] = [
    /*     {
            id: '0_minute',
            val: 0,
            unit: 'minute'
        }, */
    {
        id: '5_minute',
        val: 5,
        unit: 'minute'
    },
    {
        id: '10_minute',
        val: 10,
        unit: 'minute'
    },
    {
        id: '15_minute',
        val: 15,
        unit: 'minute'
    },
    {
        id: '30_minute',
        val: 30,
        unit: 'minute'
    },
    {
        id: '1_hour',
        val: 1,
        unit: 'hour'
    },
    {
        id: '2_hour',
        val: 2,
        unit: 'hour'
    },
    {
        id: '1_day',
        val: 1,
        unit: 'day'
    },
    {
        id: '2_day',
        val: 2,
        unit: 'day'
    },
    /*     {
            id: '1_week',
            val: 1,
            unit: 'week'
        } */
]