import { useEffect, useRef, useState } from "react";

interface VideoPlayerProps {
    url: string
    thumbnail?: string
    onVideoEnd: (ref: React.RefObject<HTMLVideoElement>) => void
}
// TODO: Make overlay for controls
const VideoPlayer = (props: VideoPlayerProps) => {
    const vidRef = useRef<HTMLVideoElement>(null)
    const [currentVideo, setCurrentVideo] = useState<string>()
    const time = useRef<number>()
    const [controlsEnabled, setControlsEnabled] = useState(false);
    // const [showControls, setShowControls] = useState<boolean>(false)

    useEffect(() => {
        setCurrentVideo(props.url)
    }, [props.url])

    useEffect(() => {
        const timeupdateListener = () => {
            if (!vidRef.current?.seeking) {
                time.current = vidRef.current?.currentTime
            }
        }

        const seekingListener = () => {
            var delta = (vidRef.current && time.current && vidRef.current.currentTime - time.current) || 0
            console.log(delta)
            if (Math.abs(delta) > 0.01) {
                if (vidRef.current) {
                    vidRef.current.currentTime = time.current as number
                }
            }
        }

        const endedListener = () => {
            time.current = 0;
        }

        vidRef.current?.addEventListener('timeupdate', timeupdateListener)
        vidRef.current?.addEventListener('seeking', seekingListener)
        vidRef.current?.addEventListener('ended', endedListener);

        setTimeout(() => {
            setControlsEnabled(true);
        }, 3000);

        return () => {
            vidRef.current?.removeEventListener('timeupdate', timeupdateListener)
            vidRef.current?.removeEventListener('seeking', seekingListener)
            vidRef.current?.removeEventListener('ended', endedListener)
        }
    }, [])

    return (
        <>
            <div className="video-player-container">
                <video ref={vidRef} autoPlay poster={props.thumbnail ? props.thumbnail : ""} controls={controlsEnabled} onEnded={() => props.onVideoEnd(vidRef)} src={currentVideo}>Your browser does not support videos.</video>
                {/* <div style={{ opacity: showControls ? '1' : '0' }} className={"controls-container"}>
                    Controls
                </div> */}
            </div>
        </>
    )
}

export default VideoPlayer;