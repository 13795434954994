import "reflect-metadata";

export interface IJsonMetaData<T> {
    required?: boolean,
    name?: string,
    clazz?: { new(): T },
    type: string
}

export let jsonRegistry: { [key: string]: string[] } = {
    'BaseModel': ['id', 'created_at', 'updated_at', 'deleted_at']
};
export let fullJsonRegistry: { [key: string]: { properties: { [key: string]: string }, modelName: string, isHelperModel: boolean, excludeFromCaching: boolean } } = {
    'BaseModel': {
        properties: {
            id: 'string'
        },
        modelName: 'BaseModel',
        isHelperModel: false,
        excludeFromCaching: false
    }
};
const jsonMetadataKey = "jsonProperty";
export type JsonPropertyReceiver = (target: Object, targetKey: string) => void;

export function JsonProperty<T>(metadata?: IJsonMetaData<T> | string): JsonPropertyReceiver {
    if (metadata instanceof String || typeof metadata === "string") {
        let data = Reflect.metadata(jsonMetadataKey, {
            name: metadata,
            required: false,
            clazz: undefined,
            type: 'any'
        });

        return (target: Object, targetKey: string): void => {
            if (!jsonRegistry[target.constructor.name]) {
                jsonRegistry[target.constructor.name] = []
            }
            jsonRegistry[target.constructor.name].push(targetKey);
            return data(target, targetKey);
        }
    } else {
        let metadataObj = <IJsonMetaData<T>>metadata;
        let required = true;
        if (metadataObj && typeof metadataObj.required === 'boolean') {
            required = metadataObj.required
        } else if (metadataObj.type.endsWith('?')) {
            required = false
        }

        let data = Reflect.metadata(jsonMetadataKey, {
            name: metadataObj ? metadataObj.name : undefined,
            clazz: metadataObj ? metadataObj.clazz : undefined,
            required: required,
            type: metadataObj ? metadataObj.type : 'any'
        });

        if (metadataObj && metadataObj.type === 'Date') {
            metadataObj.type = 'date';
        } else if (metadataObj && metadataObj.type === 'Date?') {
            metadataObj.type = 'date?';
        }
        return (target: any, targetKey: string): void => {
            if (!jsonRegistry[target.constructor.name]) {
                fullJsonRegistry[target.constructor.name] = {
                    properties: {},
                    modelName: '',
                    isHelperModel: false,
                    excludeFromCaching: false,
                }
                jsonRegistry[target.constructor.name] = []
            }
            if (targetKey === 'ISHELPERMODEL') {
                fullJsonRegistry[target.constructor.name].isHelperModel = metadataObj.type !== 'false'
                return data(target, targetKey);
            } else if (targetKey === 'MODELNAME') {
                fullJsonRegistry[target.constructor.name].modelName = metadataObj.type
                return data(target, targetKey);
            } else if (targetKey === 'FCEXCLUDEFROMCACHING') {
                fullJsonRegistry[target.constructor.name].excludeFromCaching = true
                return data(target, targetKey);
            }

            fullJsonRegistry[target.constructor.name]['properties'][targetKey] = metadataObj ? metadataObj.type : 'any';
            jsonRegistry[target.constructor.name].push(targetKey);
            return data(target, targetKey);
        }
    }
}

export function getClazz(target: any, propertyKey: string): any {
    return Reflect.getMetadata("design:type", target, propertyKey)
}

export function getJsonProperty<T>(target: any, propertyKey: string): IJsonMetaData<T> {
    return Reflect.getMetadata(jsonMetadataKey, target, propertyKey);
}

export default class MapUtils {

    static isPrimitive(obj: any) {
        switch (typeof obj) {
            case "string":
            case "number":
            case "boolean":
                return true;
        }
        return (obj instanceof String || obj === String ||
            obj instanceof Number || obj === Number ||
            obj instanceof Boolean || obj === Boolean ||
            obj instanceof Date || obj === Date);
    }

    static isArray(object: any) {
        if (object === Array) {
            return true;
        } else if (typeof Array.isArray === "function") {
            return Array.isArray(object);
        }
        else {
            return (object instanceof Array);
        }
    }

    static deserialize<T>(clazz: { new(): T }, jsonObject: any, keyPath?: string): T | undefined {
        if ((clazz === undefined) || (jsonObject === undefined || jsonObject === null)) return undefined;
        let obj: any = new clazz();
        let attributes: any = [];
        (jsonRegistry["BaseModel"] || []).forEach((attribute) => {
            attributes.push(attribute);
        });
        (jsonRegistry[clazz.name] || []).forEach((attribute) => {
            attributes.push(attribute);
        });
        attributes.forEach((key: any) => {
            let propertyMetadataFn: (IJsonMetaData: any) => any = (propertyMetadata) => {
                let propertyName = propertyMetadata.name || key;
                let innerJson = jsonObject ? jsonObject[propertyName] : undefined;
                let clazz = getClazz(obj, key);
                if (MapUtils.isArray(clazz)) {
                    let metadata = getJsonProperty(obj, key);
                    if (metadata.clazz || MapUtils.isPrimitive(clazz)) {
                        if (innerJson && MapUtils.isArray(innerJson)) {
                            return innerJson.map(
                                (item: any) => MapUtils.deserialize(propertyMetadata.clazz, item, keyPath ? keyPath + key : key)
                            );
                        } else {
                            return undefined;
                        }
                    } else {
                        return innerJson;
                    }

                } else if (!MapUtils.isPrimitive(clazz)) {
                    if (typeof propertyMetadata.clazz === 'function') {
                        if (innerJson === undefined || innerJson === null) {
                            return undefined;
                        }
                        let object = MapUtils.deserialize(propertyMetadata.clazz, innerJson, keyPath ? keyPath + key : key);
                        if (propertyMetadata.required && (object === undefined || object === null)) {
                            throw (new Error(`Missing ${clazz.name}.${keyPath ? keyPath + key : key}`));
                        }
                        return object;
                    } else {
                        if (innerJson === undefined || innerJson === null) {
                            return undefined;
                        }
                        return Object.assign({}, innerJson);
                    }
                } else {
                    if (propertyMetadata.required && (jsonObject[propertyName] === undefined || jsonObject[propertyName] === null)) {
                        throw (new Error(`Missing ${clazz.name}.${keyPath ? keyPath + key : key}`));
                    }
                    if (jsonObject && propertyMetadata.type.startsWith('number') && typeof jsonObject[propertyName] === 'string') {
                        return parseFloat(jsonObject[propertyName]);
                    }
                    return jsonObject ? jsonObject[propertyName] : undefined;
                }
            };

            let propertyMetadata = getJsonProperty(obj, key);
            if (propertyMetadata) {
                obj[key] = propertyMetadataFn(propertyMetadata);
            } else {
                if (jsonObject && jsonObject[key] !== undefined) {
                    obj[key] = jsonObject[key];
                }
            }
        });
        return obj;
    }
}