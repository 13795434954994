import { Translate } from "../sdk/translate";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import { useEffect, useState } from "react";
import { News } from "../sdk/api/models/news";
import { API as NewsAPI } from '../sdk/api/endpoints/news';

// Assets
import {
    ARROW_LEFT,
    BOOKMARK,
    BOOKMARK_FILLED,
    CHECK,
    TIMES
} from "../assets/exports";
import { FitjoLoader } from "../components/FitjoLoader";
import { Util } from "../sdk/utils/util";
import { APIErrorType } from "../sdk/api/errors";
import { messageType } from "../sdk/utils/types";
import GenericPopup from "../components/GenericPopup";

type Props = {
    news: Array<News>
};
type NewsSingleProps = Props & RouteComponentProps<{}>;

const NewsSingle = (props: NewsSingleProps) => {
    const history = useHistory();
    const [isFavorite, setFavorite] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const params = useParams<{ id }>()
    const [loading, setLoading] = useState<boolean>(true)
    const [article, setArticle] = useState<News>()
    const [headline, setHeadline] = useState<string>('')
    const [favLoading, setFavLoading] = useState<boolean>(false)
    const [message, setMessage] = useState<[string, messageType]>(['', 'info'])

    useEffect(() => {
        props.news.every((article) => {
            if (article.id === params.id) {
                checkIsFavourite()
                setHeadline(article.headline)
                setArticle(article)
                setLoading(false)
                return false
            }
            return true
        })
    }, [])

    const handleFav = () => {
        setFavLoading(true)
        if (isFavorite) {
            NewsAPI.removeFavorite(article!.id!).then(() => {
                setFavorite(false);
                setFavLoading(false);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 650)
            }).catch((err) => {
                setFavLoading(false);
                setMessage([Translate.CouldNotRemoveFavorite, 'error']);
            })
        }
        else {
            NewsAPI.addFavorite(article!.id!).then(() => {
                setFavorite(true);
                setFavLoading(false);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 650)
            }).catch((err) => {
                setFavLoading(false);
                setMessage([Translate.CouldNotAddFavorite, 'error']);
            })
        }
    }

    const checkIsFavourite = () => {
        setFavLoading(true)
        NewsAPI.getFavorites().then((favorites) => {
            favorites.every((favorite) => {
                if (favorite.id === params.id) {
                    setFavorite(true)
                    return false;
                }
                return true;
            })
            setFavLoading(false)
        }).catch((err: APIErrorType) => {
            console.log('Error', err)
            setMessage([Translate.GeneralError, 'error'])
        })
    }

    return (
        <>
            {
                !loading ?
                    <div className="news-single-container">
                        <div className="header-image" style={{ backgroundImage: `url(${article?.img_url})` }} />
                        <div className="content">
                            <div className="header">
                                <>{headline}</>
                                <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                                <div className="fav-container">
                                    {
                                        favLoading ? <FitjoLoader /> : <img onClick={() => handleFav()} className="bookmark" src={isFavorite ? BOOKMARK_FILLED : BOOKMARK} alt="" />
                                    }
                                </div>
                            </div>
                            {
                                Util.splitAtLinebreaks(article!.text).map((text, key) => {
                                    return (
                                        <div key={key} className="news-description">{text}</div>
                                    )
                                })
                            }
                        </div>
                        <div style={{ top: showPopup ? "7rem" : "0", opacity: showPopup ? 1 : 0 }} className="popup-container">
                            <div className="icon-container">
                                <img className="icon" src={isFavorite ? CHECK : TIMES} alt="" />
                            </div>
                            <div className="text">{isFavorite ? Translate.SavedUnder('news') : Translate.RemovedFrom('news')}</div>
                        </div>
                        <GenericPopup show={message[0] !== ''} message={message} setMessageState={setMessage} />
                    </div>
                    :
                    <div className="loading-page">
                        <div className="loader-container">
                            <FitjoLoader />
                        </div>
                    </div>
            }
        </>
    );
}

export default NewsSingle;