import { useEffect, useState } from "react";
import { NavItems } from "../models/nav-items";
import { useHistory } from "react-router";

interface DropdownBoxProps {
    image: string,
    header: string,
    text?: string,
    buttonText: string,
    dropdownLinks?: Array<NavItems>,
    singleLink?: string
}

const DropdownBox = (props: DropdownBoxProps) => {
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const [screenWidth, setWidth] = useState<number>(window.innerWidth);
    var countLinks = "";
    if (props.dropdownLinks) {
        countLinks = props.dropdownLinks.length * 2 + 3 + "rem";
    }

    const changeWidth = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', changeWidth);

        return (() => {
            window.removeEventListener('resize', changeWidth);
        })
    }, [screenWidth]);

    return (
        <>
            <div style={{
                backgroundImage: `url(${props.image})`,
                borderBottomLeftRadius: open ? `0` : `15px`,
                borderBottomRightRadius: open ? `0` : `15px`,
                marginBottom: open && screenWidth <= 1200 ? countLinks : ``,
            }}
                className={open ? "dropdownBox-container open" : "dropdownBox-container"}
            >
                <div className="dropdownBox-header">{props.header}</div>
                {props.text &&
                    <div className="text">{props.text}</div>
                }
                <div className="button" onClick={() => {
                    if (props.dropdownLinks)
                        setOpen(!open);
                    else {
                        if (props.singleLink)
                            history.push(props.singleLink);
                    }
                }}>{props.buttonText}</div>
                <div className={open ? "dropdown-container open" : "dropdown-container"}>
                    {props.dropdownLinks && props.dropdownLinks.map((item, key) => {
                        return (
                            <div key={key} className="element" onClick={() => history.push(item.url)}>{item.page}</div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default DropdownBox;