import { Translate } from "../sdk/translate";
import { useHistory } from "react-router";

// Assets
import { LOGO_FULL } from "../assets/exports";

const Fitjo = () => {
    const history = useHistory();

    return (
        <>
            <div className="fitjo-container">
                <img className="logo" src={LOGO_FULL} alt="" />
                <div className="text">{Translate.FitjoInfo}</div>
                <div className="row">
                    <div onClick={() => history.push('/fitjo/info')} className="element">FITJO</div>
                    <div onClick={() => history.push('/fitjo/discounts')} className="element green">{Translate.Discounts}</div>
                </div>
            </div>
        </>
    )
}

export default Fitjo;