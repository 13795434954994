import { useEffect, useRef, useState } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import { ARROW_LEFT } from "../assets/exports";
import { FitjoLoader } from "../components/FitjoLoader";
import { Training } from "../sdk/api/models/training";
import { Translate } from "../sdk/translate";
import { API as PointsAPI } from "../sdk/api/endpoints/points";
import { messageType } from "../sdk/utils/types";
import GenericPopup from "../components/GenericPopup";

type Props = {
    videos: Array<Training>
};
type VideoProps = Props & RouteComponentProps<{}>;

const Video = (props: VideoProps) => {
    const vidRef = useRef<HTMLVideoElement>(null)
    const history = useHistory()
    const [currentVideo, setCurrentVideo] = useState<Training>()
    const [loading, setLoading] = useState<boolean>(true)
    const params = useParams<{ id }>()
    const [message, setMessage] = useState<[string, messageType]>(['', 'info'])
    const [showPopup, setShowPopup] = useState(false)
    const [popupMsg, setPopupMsg] = useState("")
    const [isMeditation, setIsMeditation] = useState(false)
    const time = useRef<number>()
    const [controlsEnabled, setControlsEnabled] = useState(false);

    useEffect(() => {
        const timeupdateListener = () => {
            if (!vidRef.current?.seeking) {
                time.current = vidRef.current?.currentTime
            }
        }

        const seekingListener = () => {
            var delta = (vidRef.current && time.current && vidRef.current.currentTime - time.current) || 0
            console.log(delta)
            if (Math.abs(delta) > 0.01) {
                if (vidRef.current) {
                    vidRef.current.currentTime = time.current as number
                }
            }
        }

        const endedListener = () => {
            time.current = 0;
        }

        if (!loading) {
            vidRef.current?.addEventListener('timeupdate', timeupdateListener)
            vidRef.current?.addEventListener('seeking', seekingListener)
            vidRef.current?.addEventListener('ended', endedListener);

            setTimeout(() => {
                setControlsEnabled(true);
            }, 3000);

            return () => {
                vidRef.current?.removeEventListener('timeupdate', timeupdateListener)
                vidRef.current?.removeEventListener('seeking', seekingListener)
                vidRef.current?.removeEventListener('ended', endedListener)
            }
        }
    }, [loading])

    useEffect(() => {
        props.videos.every((video) => {
            if (video.id === params.id) {
                if (video.type === 'meditation') {
                    setIsMeditation(true)
                }
                setCurrentVideo(video)
                setLoading(false)
                return false
            }
            return true
        })
    }, [props, params])

    const onVideoEnd = () => {
        setLoading(true);
        PointsAPI.addPoints(currentVideo!.points, currentVideo!.type === 'meditation' ? 'meditation' : 'training_home', currentVideo!.id).then(res_points => {
            //setMessage([Translate.FeedbackWorkoutText(currentVideo!.points), 'success'])

            if (res_points.count !== currentVideo!.points) {
                setPopupMsg(`${Translate.FeedbackWorkoutText(res_points.count)}\n${Translate.DailyPointsLimitReached(res_points.type)}`);
            } else {
                setPopupMsg(Translate.FeedbackWorkoutText(currentVideo ? currentVideo.points : 0));
            }

            setShowPopup(true)
        }).catch((err) => {
            console.log('Error', err)
            setMessage([Translate.AddPointsError, 'error'])
        }).finally(() => {
            setLoading(false)
        })
    }

    const onOk = () => {
        setShowPopup(false);
    }

    return (
        <>
            {
                !loading ?
                    <div className="video-page">
                        <div className="header">
                            <>{Translate.Back}</>
                            <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                        </div>
                        <div className={isMeditation ? "video-full-page-container meditation" : "video-full-page-container"}>
                            <video ref={vidRef} autoPlay controls={controlsEnabled} poster={currentVideo?.video_thumbnail} onEnded={onVideoEnd} src={currentVideo?.video_url}>Your browser does not support videos.</video>
                        </div>
                        <div style={{ display: showPopup ? 'flex' : 'none' }} className="popup-container">
                            <div style={{whiteSpace: "pre-line"}} className="text">{popupMsg}</div>
                            <div onClick={() => onOk()} className="button">{Translate.Ok}</div>
                        </div>
                        <GenericPopup show={message[0] !== ''} message={message} setMessageState={setMessage} />
                    </div>
                    :
                    <div className="loading-page">
                        <div className="loader-container">
                            <FitjoLoader />
                        </div>
                    </div>
            }
        </>
    )
}

export default Video;