import { NavItems } from "../models/nav-items";
import { useHistory, useLocation } from "react-router";

// Assets
import { LOGO, NO_USER } from '../assets/exports';
import { useContext } from "react";
import { UserContext } from "../context/user";

interface HeaderProps {
    navItems: NavItems[],
    onMenuClick: () => void;
    sidebarOpen: boolean;
}

const Header = (props: HeaderProps) => {
    const USER_ICON = useContext(UserContext).profile_image_url;
    const history = useHistory()
    const location = useLocation()

    return (
        <>
            <header className="header-container">
                <img src={LOGO} alt="" className="header-logo" onClick={() => history.push('/survey')} />
                <div className="link-container">
                    {props.navItems.map((item, key) => {
                        return (
                            <div key={key} className={"/" + location.pathname.split('/')[1] === item.url ? "button active" : "button"} onClick={() => history.push(item.url)}>{item.page}</div>
                        );
                    })}
                    <div onClick={props.onMenuClick} className="menu-container">
                        <div className="burger" />
                    </div>
                    <div onClick={() => history.push('/profile')} className="user-icon" style={{ backgroundImage: `url(${USER_ICON ? USER_ICON : NO_USER})` }} />
                </div>
            </header>
        </>
    );
}

export default Header;