import { SURVEY_1, SURVEY_2, SURVEY_3 } from "../assets/exports";
import DropdownBox from "../components/DropdownBox";
import { NavItems } from "../models/nav-items";
import { Translate } from "../sdk/translate";

const Survey = () => {

    return (
        <>
            <div className="survey">
                <div className="row">
                    <div className="header">{Translate.Survey}</div>
                    <DropdownBox
                        image={SURVEY_1}
                        header={Translate.Training}
                        text={Translate.TrainingWebtext}
                        buttonText={Translate.SeeMore}
                        dropdownLinks={trainingLinks}
                    />
                    <DropdownBox
                        image={SURVEY_2}
                        header={Translate.Nutrition}
                        text={Translate.NutritionWebtext}
                        buttonText={Translate.SeeMore}
                        dropdownLinks={nutritionLinks}
                    />
                    <DropdownBox
                        image={SURVEY_3}
                        header={Translate.Meditation}
                        text={Translate.MeditationWebtext}
                        buttonText={Translate.SeeMore}
                        singleLink="/survey/meditation"
                    />
                </div>
            </div>
        </>
    );
}

export default Survey;

const trainingLinks: Array<NavItems> = [
    {
        page: Translate.Business,
        url: '/survey/business'
    },
    {
        page: Translate.Home,
        url: '/survey/home'
    },
    // {
    //     page: Translate.Footsteps,
    //     url: '/survey/steps'
    // },
]

const nutritionLinks: Array<NavItems> = [
    {
        page: Translate.Breakfast,
        url: '/survey/nutrition/breakfast'
    },
    {
        page: Translate.ColdFood,
        url: '/survey/nutrition/cold'
    },
    {
        page: Translate.WarmFood,
        url: '/survey/nutrition/warm'
    },
    {
        page: Translate.Snacks,
        url: '/survey/nutrition/snack'
    },
]
