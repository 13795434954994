import { APIVersion, AuthenticatedRequest, HTTPMethod } from "../requests/request";


export class API { 


    /**
     * 
     * @param message 
     */
    public static sendContactRequest(message: string): Promise<{message: string}> {
        let apiReqest = new AuthenticatedRequest<{message: string}>(`/contact/request`, HTTPMethod.POST, APIVersion.v1_0, undefined, {
            message
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }
}