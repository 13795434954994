import { useEffect, useState } from 'react';
import { Router, Route, Switch } from 'react-router';
import Authorized from './Authorized';
import { UserContextProvider } from './context/user';
import history from './history';
import Login from './Login';
import { Device } from './sdk/api/models/device';
import { User } from './sdk/api/models/user';
import { Session } from './sdk/api/requests/request';
import { API as UserAPI } from './sdk/api/endpoints/user';

const InitialState = undefined as any

const App = () => {
  const [user, setUser] = useState<User>(InitialState)

  useEffect(() => {
    Session.setUserUpdatedHandler((user) => {
      setUser(user)
    })

    if (localStorage.getItem('user')) {
      try {
        let userFromData = JSON.parse(localStorage.getItem('user')!) as Device
        setUser(userFromData.User!)
        Session.setSession(userFromData.session_token)
        UserAPI.getUser().then((user) => {
          setUser(user)
          userFromData.User = user
          localStorage.setItem('user', JSON.stringify(userFromData))
        }).catch((err) => {
          console.log('Error', err)
          setUser(InitialState)
        })
      }
      catch (err) {
      }
    }
  }, [])

  return (
    <>
      <UserContextProvider value={user}>
        <Router history={history}>
          <Switch>
            <Route component={user ? Authorized : Login} />
          </Switch>
        </Router>
      </UserContextProvider>
    </>
  );
}

export default App;