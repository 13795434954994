import { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { SidebarContext } from "../context/sidebar";
import { NavItems } from "../models/nav-items";
import React from "react";

// Assets
import { TIMES } from "../assets/exports";


interface Sidebarprobs {
    onCloseClick: () => void,
    navItems: NavItems[]
}

const Sidebar = (props: Sidebarprobs) => {
    const history = useHistory();
    const location = useLocation();
    const sidebarContext = useContext(SidebarContext);
    const sidebarRef = React.useRef<HTMLDivElement>(null)

    function linkClick(url: string) {
        history.push(url);
        props.onCloseClick();
    }

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (sidebarContext.open && sidebarRef.current && !sidebarRef.current.contains(event.target as any)) {
                props.onCloseClick();
            }
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [sidebarRef, props, sidebarContext.open])

    return (
        <>
            <div ref={sidebarRef} className={`sidebar-container ${sidebarContext.open ? 'sidebar-open' : 'closed'}`}>
                <div className="close-button-container">
                    <img src={TIMES} onClick={() => props.onCloseClick()} className="close-button" alt="" />
                </div>
                <div className="link-container">
                    {props.navItems.map((item, key) => {
                        return (
                            <div key={key} className={"/" + location.pathname.split('/')[1] === item.url ? "button active" : "button"} onClick={() => linkClick(item.url)}>{item.page}</div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default Sidebar;