import { Calendar } from 'antd';
import { Route, Switch, useHistory } from 'react-router';
import { Translate } from '../sdk/translate';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Util } from '../sdk/utils/util';
import { MONTHS } from '../sdk/utils/constants';
import { CalendarEvent } from '../sdk/api/models/calendarEvent';
import { API as CalendarAPI } from '../sdk/api/endpoints/calendar';

// Assets
import { ARROW_LEFT } from '../assets/exports';
import { FitjoLoader } from '../components/FitjoLoader';
import CalendarMonth from './CalendarMonth';

const CalendarPage = (props) => {
    const history = useHistory();
    const [dates, setDates] = useState<Array<Array<moment.Moment>>>([]);
    const selectedDate = moment();
    const tmp: Array<Array<moment.Moment>> = []
    const [events, setEvents] = useState<Array<CalendarEvent>>([])
    const [loading, setLoading] = useState<boolean>(true)

    let startDate: moment.Moment;
    let endDate: moment.Moment;

    function dateCellRender(value) {
        let eventFound = false
        events.every((event) => {
            let start = moment(event.start_date).hours(0).minutes(0)
            let end = moment(event.end_date).hours(23).minutes(59).seconds(59)
            if (Util.momentInRange(value, [start, end])) {
                eventFound = true
                return false
            }
            return true
        })
        if (eventFound)
            return (
                <div className="dot">•</div>
            );
    }

    useEffect(() => {
        for (let i = 0; i < 4; i++) {
            startDate = moment().startOf('month').add(i, 'month');
            endDate = moment().add(i, 'month').endOf('month');
            tmp.push([startDate, endDate]);
        }
        setDates(tmp);

        CalendarAPI.getCalendarEvents().then((events) => {
            setEvents(events)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <Switch>
            <Route path="/profile/calendar/:year/:month" component={() => <CalendarMonth {...props} events={events} />} />
            <Route render={() => {
                return (
                    <>
                        <div className="calendar-page-container">
                            <div className="header-wrapper">
                                <div className="header">
                                    <>{Translate.Calendar}</>
                                    <img onClick={() => history.push('/profile')} className="arrow-left" src={ARROW_LEFT} alt="" />
                                </div>
                            </div>
                            <div className="calendar-row">
                                {
                                    !loading ?
                                        dates.map((range, key) => {
                                            return (
                                                <div key={key} onClick={() => history.push('/profile/calendar/' + range[0].year() + "/" + MONTHS[range[0].month()])} className="single-calendar">
                                                    <div className="calendar-header-container">
                                                        {MONTHS[range[0].month()] + ", " + range[0].year()}
                                                    </div>
                                                    <Calendar dateCellRender={dateCellRender} className={`overwrite`} value={Util.momentInRange(selectedDate, [range[0], range[1]]) ? selectedDate : range[0]} defaultValue={range[0]} key={key} fullscreen={false} />
                                                </div>
                                            )
                                        })
                                        :
                                        <div className='loader-container'>
                                            <FitjoLoader />
                                        </div>
                                }
                            </div>
                        </div>
                    </>

                )
            }} />
        </Switch>
    )
}

export default CalendarPage;