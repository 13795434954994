import { useEffect, useState } from "react";
import { Translate } from "./sdk/translate";
import { Button, Input } from "antd";
import { useHistory } from "react-router";
import GenericPopup from "./components/GenericPopup";
import { messageType } from "./sdk/utils/types";

// Assets
import { LOGO, LOGIN_IMAGE_1 } from "./assets/exports";
import validator from "validator";
import { UserAPI } from "./sdk/api/endpoints";

const ForgotPwd = () => {
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<[string, messageType]>(['', 'info']);
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                if (enableButton()) {
                    submit()
                }
                else
                    setMessage(['Bitte fülle alle Felder aus', 'error']);
            }
        };

        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [email, enableButton])

    function enableButton(): boolean {
        return email.trim() !== '' && validator.isEmail(email)
    }

    function submit() {
        setLoading(true)
        UserAPI.forgotPassword(email).then(() => {
            setMessage([Translate.EmailSend, 'success'])
        }).catch((err) => {
            setMessage([Translate.EmailNotFound, 'error'])
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <div className="forgot-pwd-container">
                <div style={{ backgroundImage: `url(${LOGIN_IMAGE_1})` }} className="image">
                    <img className="logo" src={LOGO} alt="" />
                </div>
                <div className="right-side">
                    <div className="header">{Translate.ForgotPassword}</div>
                    <Input className="input" value={email} onChange={(event) => setEmail(event.target.value)} placeholder={Translate.Email} />
                    <div className="forgot-password-container">
                        <div onClick={() => history.goBack()} className="forgot-password">{Translate.BackToLogin}</div>
                    </div>
                    <Button onClick={() => submit()} type="primary" disabled={!enableButton()} loading={loading} style={{
                        backgroundColor: enableButton() ? '#9FC0A3' : '#D7D7D7',
                        cursor: enableButton() ? 'pointer' : 'default'
                    }} className="login-button">{Translate.Next}</Button>
                </div>
                <GenericPopup show={message[0] !== ''} setMessageState={setMessage} message={message} />
            </div>
        </>
    );
}

export default ForgotPwd;