import { useHistory } from "react-router";
import { Translate } from "../sdk/translate";
import { Input } from 'antd';
import { useCallback, useEffect, useRef, useState } from "react";
import { API as NewsAPI } from '../sdk/api/endpoints/news';
import { News as NewsModel } from "../sdk/api/models/news";
import debounce from 'lodash/debounce'

// Assets
import {
    NEWS_IMAGE_1,
    NEWS_IMAGE_2,
    NEWS_IMAGE_3,
} from '../assets/exports';
import { FitjoLoader } from "../components/FitjoLoader";

const Search = Input

const News = () => {
    const history = useHistory();
    const [searchbarValue, setSearchbarValue] = useState<string>('')
    const [news, setNews] = useState<Array<NewsModel>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const searchBarRef = useRef(searchbarValue)

    const search = useCallback(debounce((searchbarValue: string) => {
        searchBarRef.current = searchbarValue
        if (searchbarValue !== '') {
            setLoading(true)
            NewsAPI.getNews(undefined, searchbarValue).then((newsArr) => {
                if (searchBarRef.current == searchbarValue) {
                    setNews(newsArr)
                }
            }).catch((err) => {
            }).finally(() => {
                setLoading(false)
            })
        }
        else {
            setNews([])
        }
    }, 400), [])

    useEffect(() => {
        search(searchbarValue)
    }, [searchbarValue])

    return (
        <>
            <div className="news-container">
                <div className="header">{Translate.News}</div>
                <div className="info-text">{Translate.NewsInfoText}</div>
                <Search className="searchbar" type={'search'} placeholder={Translate.Search} onChange={(event) => setSearchbarValue(event.target.value)} value={searchbarValue} />
                <div className="row">
                    {
                        !loading ?

                            (
                                news.length !== 0 ?
                                    news.map((article, key) => {
                                        return (
                                            <div key={key} onClick={() => history.push('/news/' + article.type + '/' + article.id)} style={{ backgroundImage: `url(${article.img_url})` }} className="element"><span className="element-text">{article.headline}</span></div>
                                        )
                                    })
                                    :
                                    <>
                                        <div onClick={() => history.push('/news/nutrition')} style={{ backgroundImage: `url(${NEWS_IMAGE_1})` }} className="element"><span className="element-text">{Translate.Nutrition}</span></div>
                                        <div onClick={() => history.push('/news/training')} style={{ backgroundImage: `url(${NEWS_IMAGE_2})` }} className="element"><span className="element-text">{Translate.TrainingExercise}</span></div>
                                        <div onClick={() => history.push('/news/office')} style={{ backgroundImage: `url(${NEWS_IMAGE_3})` }} className="element"><span className="element-text">{Translate.Office}</span></div>
                                        <div onClick={() => history.push('/news/general')} className="element"><span className="element-text">{Translate.General}</span></div>
                                    </>
                            )
                            :
                            <div className="element">
                                <div className="loader-container">
                                    <FitjoLoader />
                                </div>
                            </div>
                    }
                </div>
            </div>
        </>
    );
}

export default News;