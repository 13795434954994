import { useHistory } from "react-router";
import { Translate } from "../sdk/translate";

const Profil = () => {
    const history = useHistory();

    return (
        <>
            <div className="profil-container">
                <div className="header">{Translate.MyProfil}</div>
                <div className="row">
                    <div onClick={() => history.push('/profile/account')} className="single-element green">
                        {Translate.Account}
                    </div>
                    <div onClick={() => history.push('/profile/challenge')} className="single-element blue">
                        {Translate.Challenge}
                    </div>
                    <div onClick={() => history.push('/profile/calendar')} className="single-element green">
                        {Translate.Calendar}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profil;