import { APIError } from './../api/requests/request';
import { ReminderUnit } from './types';
import { EventType } from "../api/models/calendarEvent";
import { Translate } from "../translate";
import { Colors } from '../styles/colors';
import moment from 'moment';
import { APIErrorType } from '../api/errors';

const POINTS_FOR_BRONZE = 100
const POINTS_FOR_SILVER = 500
const POINTS_FOR_GOLD = 1000

export class Util {

    public static getMonthAbbreviation(month: number) {
        switch (month) {
            case 0:
                return Translate.Jan
            case 1:
                return Translate.Feb
            case 2:
                return Translate.Mar
            case 3:
                return Translate.Apr
            case 4:
                return Translate.Mai
            case 5:
                return Translate.Jun
            case 6:
                return Translate.Jul
            case 7:
                return Translate.Aug
            case 8:
                return Translate.Sep
            case 9:
                return Translate.Oct
            case 10:
                return Translate.Nov
            case 11:
                return Translate.Dec
        }
    }

    public static getEventTypeLabel(type: EventType) {
        switch (type) {
            case 'training_business':
                return `${Translate.Training} ${Translate.Business}`
            case 'training_home':
                return `${Translate.Training} ${Translate.Home}`
            case 'meditation':
                return Translate.Meditation

        }
    }

    public static getReminderFactor(unit: ReminderUnit) {
        switch (unit) {
            case 'minute':
                return 1;
            case 'hour':
                return 60;
            case 'day':
                return 24 * 60;
            case 'week':
                return 7 * 24 * 60;
        }
    }

    public static getStatusColor(points: number) {
        if (points >= POINTS_FOR_GOLD) {
            return Colors.Gold
        } else if (points >= POINTS_FOR_SILVER) {
            return Colors.Silver
        } else if (points >= POINTS_FOR_BRONZE) {
            return Colors.Bronze
        } else {
            return 'transparent'
        }
    }

    public static splitAtLinebreaks(text: string) {
        return text.split('\n');
    }

    public static momentInRange(moment: moment.Moment, range: [moment.Moment, moment.Moment]) {
        return range[0].valueOf() <= moment.valueOf() && moment.valueOf() <= range[1].valueOf()
    }

    public static errorToString(err: { type?: APIErrorType, message: string }, defaultMessage = 'An unhandled error occurred. Please try again later.'): Array<string> {
        if (err && err.type === APIErrorType.InvalidParameters) {
            if ((err as any).missing_keys && (err as any).missing_keys.length) {
                return (err as any).missing_keys.map((k: string) => k + ' is missing')
            }
            return Object.keys((err as any).invalid_keys).map((key) => {
                return key + ': ' + (err as any).invalid_keys[key]
            })
        } else if (err && err.type === APIErrorType.AlreadyExists || err && err.message) {
            return [err.message]
        } else {
            return [defaultMessage]
        }
    }
}

