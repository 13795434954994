import { JsonProperty } from "../requests/jsonparser";
import { BaseModel } from "./base";


export class CompanyEvent extends BaseModel {

    @JsonProperty({ type: 'string' })
    name: string;

    @JsonProperty({ type: 'string?' })
    img_url?: string;

    @JsonProperty({ type: 'string?' })
    url?: string;

    @JsonProperty({ type: 'string' })
    description: string;
}