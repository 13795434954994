import { CompanyEvent } from "../models/companyEvent";
import { APIVersion, AuthenticatedRequest, HTTPMethod } from "../requests/request";


export class API { 

    /**
     * Returns the Events of a company
     */
    public static getCompanyEvents(): Promise<CompanyEvent[]> {
        let apiReqest = new AuthenticatedRequest<CompanyEvent>('/companies/events', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send(CompanyEvent).then((response) => {
            return <CompanyEvent[]>response.data
        })
    }
}