import { RouteComponentProps, useHistory, useParams } from "react-router";
import { Translate } from "../sdk/translate";
import { useEffect, useState } from 'react';
import FavoritesBox from "../components/FavoritesBox";
import { News } from "../sdk/api/models/news";
import { Recipe } from "../sdk/api/models/recipe";
import { API as TrainingAPI } from '../sdk/api/endpoints/training';
import { API as RecipesAPI } from '../sdk/api/endpoints/recipes';
import { API as NewsAPI } from '../sdk/api/endpoints/news';

// Assets
import {
    ARROW_LEFT,
    NEWS_IMAGE_1,
    CHECK,
    TIMES,
} from "../assets/exports";
import { FavBusinessTraining } from "../sdk/api/models/favBusinessTraining";
import { FitjoLoader } from "../components/FitjoLoader";
import { TrainingType } from "../sdk/api/models/training";

type Props = {};
type FavoritesCategoryProps = Props & RouteComponentProps<{}>;

const FavoritesCategory = (props: FavoritesCategoryProps) => {
    const history = useHistory();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [isFavorite, setFavorite] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [favoritesTraining, setFavoritesTraining] = useState<Array<FavBusinessTraining>>([])
    const [favoritesRecipes, setFavoritesRecipes] = useState<Array<Recipe>>([])
    const [favoritesNews, setFavoritesNews] = useState<Array<News>>([])
    const params = useParams<{ category }>()
    const [category, setCategory] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)
    const [noItemsText, setNoItemsText] = useState<string>('')
    const [reloadPage, setReloadPage] = useState<number>(0)

    // eslint-disable-next-line
    const handleFav = (val: boolean, title: string) => {
        setFavorite(val);
        setTitle(title);
        setShowPopup(true);
        setTimeout(() => {
            setShowPopup(false);
            if (params.category === 'training') {
                setReloadPage(reloadPage + 1)
            }
        }, 1000);
    }

    useEffect(() => {
        setLoading(true)
        switch (params.category) {
            case 'training':
                setCategory(Translate.Training)
                setNoItemsText(Translate.NoTrainings)
                TrainingAPI.getFavoriteBusinessTraining().then((trainingArr) => {
                    setFavoritesTraining(trainingArr)
                }).catch((err) => {
                    console.log('Error', err)
                }).finally(() => {
                    setLoading(false)
                })
                break
            case 'recipes':
                setCategory(Translate.Nutrition)
                setNoItemsText(Translate.NoRecipes)
                RecipesAPI.getFavorites().then((recipesArr) => {
                    if (recipesArr.length !== 0) {
                        setFavoritesRecipes(recipesArr)
                    }
                }).catch((err) => {
                    console.log('Error', err)
                }).finally(() => {
                    setLoading(false)
                })
                break
            case 'news':
                setCategory(Translate.News)
                setNoItemsText(Translate.NoNews)
                NewsAPI.getFavorites().then((newsArr) => {
                    if (newsArr.length !== 0) {
                        setFavoritesNews(newsArr)
                    }
                }).catch((err) => {
                    console.log('Error', err)
                }).finally(() => {
                    setLoading(false)
                })
                break
            default:
                history.push('/favorites')
                break
        }
    }, [reloadPage])

    return (
        <>
            <div className="favorites-category-container">
                <div className="header">
                    <>{category} {Translate.Favorites}</>
                    <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                </div>
                <div className="row">
                    {
                        !loading ?
                            ((favoritesTraining.length !== 0 || favoritesNews.length !== 0 || favoritesRecipes.length !== 0) ?
                                params.category === 'training' ?
                                    favoritesTraining.map((element, key) => {
                                        return (
                                            <FavoritesBox type="training" id={element.id!} key={key} onClick={() => history.push('/survey/business/' + element.type + '/' + element.id)} backgroundImage={''} title={element.name} onStateChange={handleFav} />
                                        )
                                    })
                                    :
                                    (params.category === 'recipes' ?
                                        favoritesRecipes.map((element, key) => {
                                            return (
                                                <FavoritesBox type="recipes" id={element.id!} key={key} onClick={() => history.push('/survey/nutrition/' + element.type + '/' + element.id)} backgroundImage={element.img_url} title={element.name} onStateChange={handleFav} />
                                            )
                                        })
                                        :
                                        params.category === 'news' ?
                                            favoritesNews.map((element, key) => {
                                                return (
                                                    <FavoritesBox type="news" id={element.id!} key={key} onClick={() => history.push('/news/' + element.type + '/' + element.id)} backgroundImage={element.img_url} title={element.headline} onStateChange={handleFav} />
                                                )
                                            }) : <></>)
                                :
                                <div className="element">
                                    {noItemsText}
                                </div>
                            )
                            :
                            <div className="element">
                                <div className="loader-container">
                                    <FitjoLoader />
                                </div>
                            </div>
                    }
                </div>
                <div style={{ top: showPopup ? "7rem" : "0", opacity: showPopup ? 1 : 0 }} className="popup-container">
                    <div className="icon-container">
                        <img className="icon" src={isFavorite ? CHECK : TIMES} alt="" />
                    </div>
                    <div className="text">{isFavorite ? Translate.TitleAdded(title) : Translate.TitleRemoved(title)}</div>
                </div>
            </div>
        </>
    )
}

export default FavoritesCategory;