import { Route, Switch, useHistory } from "react-router";
import { Translate } from "../sdk/translate";
import { API as ChallengeAPI } from '../sdk/api/endpoints/challenges';

// Assets
import { ARROW_LEFT } from "../assets/exports";
import { useEffect, useState } from "react";
import { Challenge as ChallengeType } from "../sdk/api/models/challenge";
import { FitjoLoader } from "../components/FitjoLoader";
import ChallengeSingle from "./ChallengeSingle";

const Challenge = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true)
    const [challenges, setChallenges] = useState<Array<ChallengeType>>([])

    useEffect(() => {
        ChallengeAPI.getChallenges().then((challengesArr) => {
            setChallenges(challengesArr)
        }).catch((err) => {
            console.log('Error', err)
        }).then(() => {
            setLoading(false)
        })
    }, [])

    return (
        <Switch>
            <Route path='/profile/challenge/:id' component={() => <ChallengeSingle {...props} challenges={challenges} />} />
            <Route render={() => {
                return (
                    <>
                        {
                            !loading ?
                                <div className="challenge-container">
                                    <div className="header">
                                        <>{Translate.Challenge}</>
                                        <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                                    </div>
                                    <div className="text">{Translate.ChallengeInfo}</div>
                                    <div className="row">
                                        {
                                            challenges.length !== 0 ?
                                                challenges.map((challenge, key) => {
                                                    return (
                                                        <div key={key} onClick={() => history.push('/profile/challenge/' + challenge.id)} className="single-element green">
                                                            {challenge.name}
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div style={{ cursor: 'default' }} className="single-element">{Translate.NoChallenges}</div>
                                        }
                                    </div>
                                </div>
                                :
                                <div className="loading-page">
                                    <div className="loader-container">
                                        <FitjoLoader />
                                    </div>
                                </div>
                        }
                    </>

                )
            }} />
        </Switch>
    );
}

export default Challenge;