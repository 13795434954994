import { useHistory } from "react-router";
import { Translate } from "../sdk/translate";

const MyCompany = () => {
    const history = useHistory();

    return (
        <>
            <div className="my-company">
                <div className="header">{Translate.MyCompany}</div>
                <div className="row">
                    <div onClick={() => history.push('/my-company/contact')} className="single-element blue">
                        <div className="element-header">{Translate.Contact}</div>
                    </div>
                    <div onClick={() => history.push('/my-company/events')} className="single-element green">
                        <div className="element-header">{Translate.Events}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyCompany;