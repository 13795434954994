import { useHistory } from "react-router";
import { ARROW_LEFT } from "../assets/exports";
import { Translate } from "../sdk/translate";

// Assets
import { BUSINESS1, BUSINESS2, BUSINESS3 } from "../assets/exports";


const Business = () => {
    const history = useHistory();

    return (
        <>
            <div className="business-container">
                <div className="header">
                    <>{Translate.Business}</>
                    <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                </div>
                <div className="text-header">Training Business</div>
                <div className="text">{Translate.TrainingBusiness}</div>
                <div className="row">
                    <div onClick={() => history.push('/survey/business/business_standing')} style={{ backgroundImage: `url(${BUSINESS1})` }} className="element">{Translate.Standing}</div>
                    <div onClick={() => history.push('/survey/business/business_sedentary_desk')} style={{ backgroundImage: `url(${BUSINESS2})` }} className="element">{Translate.SedentaryDesk}</div>
                    <div onClick={() => history.push('/survey/business/business_sedentary_car')} style={{ backgroundImage: `url(${BUSINESS3})` }} className="element">{Translate.SedentaryCar}</div>
                </div>
            </div>
        </>
    );
}

export default Business;