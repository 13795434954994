import { Route, RouteComponentProps, Switch, useHistory, useParams } from "react-router";
import { Translate } from "../sdk/translate";
import { Recipe, RecipeType } from "../sdk/api/models/recipe";
import { API as RecipeAPI } from '../sdk/api/endpoints/recipes';

// Assets
import { ARROW_LEFT } from "../assets/exports";
import { useEffect, useState } from "react";
import { APIError } from "../sdk/api/requests/request";
import { FitjoLoader } from "../components/FitjoLoader";
import NutritionSingle from "./NutritionSingle";

type Props = {};
type NutritionProps = Props & RouteComponentProps<{
    type: RecipeType
}>;

const Nutrition = (props: NutritionProps) => {
    const params = useParams<{ type }>()
    const [loading, setLoading] = useState<boolean>(false)
    const history = useHistory();
    const [headline, setHeadline] = useState<string>();
    const [recipes, setRecipes] = useState<Array<Recipe>>([]);

    useEffect(() => {
        switch (params.type) {
            case 'breakfast':
                setHeadline(Translate.Breakfast)
                break;
            case 'cold':
                setHeadline(Translate.ColdFood)
                break;
            case 'warm':
                setHeadline(Translate.WarmFood)
                break;
            case 'snack':
                setHeadline(Translate.Snacks)
                break;
        }

        setLoading(true);
        let promise = RecipeAPI.getRecipes(params.type)
        if (promise) {
            promise.then((recipes) => {
                setRecipes(recipes);
                setLoading(false);
            }).catch((err: APIError) => {
                setLoading(false);
                console.log('Error', err)
            })
        }
    }, [params.type])

    return (
        <>
            <Switch>
                <Route path='/survey/nutrition/:type/:id' component={() => <NutritionSingle {...props} recipes={recipes} />} />
                <Route render={() => {
                    return (
                        <div className="nutrition-container">
                            <div className="header">
                                <>{headline}</>
                                <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                            </div>
                            <div className="row">
                                {/* map api recipes */}
                                {!loading ?
                                    (recipes.length > 0 ? recipes.map((recipe, key) => {
                                        return (
                                            <div key={key} onClick={() => history.push('/survey/nutrition/' + recipe.type + '/' + recipe.id)} style={{ backgroundImage: `url(${recipe.img_url})` }} className="element"><span className="element-text">{recipe.name}</span></div>
                                        )
                                    }) : <div className="element"><span className="element-text">{Translate.NoRecipes}</span></div>)
                                    : <div className="loading-container"><div className="loader"><FitjoLoader /></div></div>}
                            </div>
                        </div>
                    )
                }} />
            </Switch>
        </>
    );
}

export default Nutrition;