import { BaseModel } from "./base";
import { JsonProperty } from "../requests/jsonparser";

export class Discount extends BaseModel {

    @JsonProperty({ type: 'string' })
    name: string;

    @JsonProperty({ type: 'string' })
    description: string;

    @JsonProperty({ type: 'string' })
    url: string;

    @JsonProperty({ type: 'string' })
    code: string;

    @JsonProperty({ type: 'string?' })
    img_url?: string;

}