import { useEffect, useState } from "react";
import { Translate } from "./sdk/translate";
import Fingerprint2 from 'fingerprintjs2'
import { DeviceData } from "./sdk/api/endpoints/device";
import validator from 'validator';
import { Session } from "./sdk/api/requests/request";
import { Button, Input } from "antd";
import { API as LoginAPI } from "./sdk/api/endpoints/login";
import { APIErrorType } from "./sdk/api/errors";
import { Route, Switch, useHistory } from "react-router";
import GenericPopup from "./components/GenericPopup";
import { messageType } from "./sdk/utils/types";
import ResetPwd from "./ResetPwd";
import ForgotPwd from "./ForgotPwd";

// Assets
import { LOGO, LOGIN_IMAGE_1, LOGIN_IMAGE_2 } from "./assets/exports";

const Login = () => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<[string, messageType]>(['', 'info']);
    const [register, setRegister] = useState<boolean>(false);
    const [mail, setMail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [name, setName] = useState<string>('');

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                if (enableButton()) {
                    register ? startRegister() : login()
                }
                else
                    setMessage(['Bitte fülle alle Felder aus', 'error']);
            }
        };

        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [mail, password, code, name, enableButton, login, register, startRegister])

    function enableButton(): boolean {
        if (register)
            return mail.trim() !== '' && password.trim() !== '' && code.trim() !== '' && name.trim() !== ''
        else
            return mail.trim() !== '' && password.trim() !== ''
    }

    function getDefaultDevice(debug: boolean, language: string): Promise<DeviceData> {
        return new Promise((resolve) => {
            Fingerprint2.getV18((result: string) => {
                let t: DeviceData = {
                    app_version: '1.0',
                    token_provider: 'web',
                    device_uuid: result,
                    build_version: '1.0',
                    debug: debug,
                    language: language
                }
                resolve(t)
            })
        })
    }

    function login() {
        if (validator.isEmail(mail)) {
            setLoading(true);
            getDefaultDevice(false, navigator.language).then((device) => {
                LoginAPI.login(mail, password, device).then((user) => {
                    setLoading(false)
                    Session.setSession(user.session_token)
                    localStorage.setItem('user', JSON.stringify(user))
                    Session.getUserUpdatedHandler()(user.User!)
                }).catch((err) => {
                    console.log('Error', err)
                    setLoading(false)
                    let text = (err.type === APIErrorType.InvalidLogin || err.type === APIErrorType.ResourceNotFound) ? Translate.CheckMailAndPW : Translate.GeneralError
                    setMessage([text, 'error'])
                }).finally(() => {
                    history.push('/survey/')
                })
            }).catch((err) => {
                console.error(err)
                setLoading(false)
                setMessage([Translate.GeneralError, 'error'])
            });
        }
        else {
            setMessage([Translate.InvalidEmail, 'error']);
        }
    }

    function startRegister() {
        if (validator.isEmail(mail)) {
            setLoading(true);
            getDefaultDevice(false, navigator.language).then((device) => {
                LoginAPI.register(code, mail, password, name, device).then((user) => {
                    setLoading(false)
                    Session.setSession(user.session_token)
                    localStorage.setItem('user', JSON.stringify(user))
                    Session.getUserUpdatedHandler()(user.User!)
                }).catch((err) => {
                    console.log('Error', err)
                    setLoading(false)
                    let text = Translate.GeneralError
                    switch (err.type) {
                        case APIErrorType.InvalidLogin || APIErrorType.ResourceNotFound:
                            text = Translate.CheckMailAndPW
                            break;
                        case APIErrorType.InactiveAccountError:
                            text = Translate.InactiveAccount
                            break;
                    }
                    setMessage([text, 'error'])
                }).finally(() => {
                    history.push('/survey/')
                })
            }).catch((err) => {
                console.log('Error', err)
                setLoading(false)
                setMessage([Translate.GeneralError, 'error'])
            });
        }
        else {
            setMessage([Translate.InvalidEmail, 'error']);
        }
    }


    return (
        <Switch>
            <Route path='/forgot-password' component={ForgotPwd} />
            <Route path='/reset-password/:token' component={ResetPwd} />
            <Route render={() => {
                return (
                    <div className="login-container">
                        <div style={{ backgroundImage: !register ? `url(${LOGIN_IMAGE_1})` : `url(${LOGIN_IMAGE_2})` }} className="image">
                            <img className="logo" src={LOGO} alt="" />
                        </div>
                        <div className="right-side">
                            <div className="header">{!register ? Translate.Login : Translate.Register}</div>
                            {register && <>
                                <Input className="input" value={code} onChange={(event) => setCode(event.target.value)} placeholder={Translate.InviteKey} />
                                <Input className="input" value={name} onChange={(event) => setName(event.target.value)} placeholder={Translate.Name} />
                            </>}
                            <Input className="input" value={mail} onChange={(event) => setMail(event.target.value)} placeholder={Translate.Email} />
                            <Input.Password className="input" value={password} onChange={(event) => setPassword(event.target.value)} placeholder={Translate.Password} />
                            <div className="forgot-password-container">
                                <div onClick={() => history.push('/forgot-password')} className="forgot-password">{Translate.ForgotPassword}</div>
                            </div>
                            <Button onClick={() => register ? startRegister() : login()} type="primary" disabled={!enableButton()} loading={loading} style={{
                                backgroundColor: enableButton() ? '#9FC0A3' : '#D7D7D7',
                                cursor: enableButton() ? 'pointer' : 'default'
                            }} className="login-button">{Translate.Next}</Button>
                            <div className="terms">{!register ? Translate.ConfirmPrivacyPolicy : Translate.RegisterConfirmPrivacyPolicy}</div>
                            <div onClick={() => setRegister(!register)} className="register-toggle">{!register ? Translate.NotRegistered : Translate.AlreadyRegistered}</div>
                        </div>
                        <GenericPopup show={message[0] !== ''} setMessageState={setMessage} message={message} />
                    </div>
                )
            }} />
        </Switch>
    );
}

export default Login;