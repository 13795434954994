import { useHistory } from "react-router";
import { Translate } from "../sdk/translate";
import { useState } from "react";
import { Util } from "../sdk/utils/util";
import { API as ContactAPI } from "../sdk/api/endpoints/contact";

// Assets
import { JOANNA_IMAGE_1, ARROW_LEFT } from "../assets/exports";
import { messageType } from "../sdk/utils/types";
import GenericPopup from "../components/GenericPopup";

const FitjoInfo = () => {
    const history = useHistory();
    const [message, setMessage] = useState<string>('');
    const [infoMessage, setInfoMessage] = useState<[string, messageType]>(['', 'info'])
    const [loading, setLoading] = useState<boolean>(false)

    const handleSend = (message: string) => {
        if (!loading) {
            if (message.length !== 0) {
                setLoading(true)
                ContactAPI.sendContactRequest(message).then(() => {
                    setInfoMessage([Translate.ContactRequestSend, 'success'])
                }).catch((err) => {
                    console.log('Error', err)
                    setInfoMessage([Translate.ContactRequestError, 'error'])
                }).finally(() => {
                    setLoading(false)
                })
            }
            else {
                setInfoMessage([Translate.GeneralError, 'error'])
            }
        }
    }

    return (
        <>
            <div className="fitjo-info-container">
                <img className="image" src={JOANNA_IMAGE_1} alt="" />
                <div className="content-container">
                    <div className="half">
                        <div className="page-header">
                            <>FITJO</>
                            <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                        </div>
                        <div className="header">{Translate.IamJoanna}</div>
                        {
                            Util.splitAtLinebreaks(Translate.About_1).map((string, key) => {
                                return (
                                    <div key={key} className="about-text">{string}</div>
                                )
                            })
                        }
                        {
                            Util.splitAtLinebreaks(Translate.About_2).map((string, key) => {
                                return (
                                    <div key={key} className="about-text">{string}</div>
                                )
                            })
                        }
                    </div>
                    <div className="half">
                        <div className="form-container">
                            <div className="contact-request">{Translate.ContactRequest}</div>
                            <div className="message-header">{Translate.Message}</div>
                            <textarea value={message} onChange={(event) => setMessage(event.target.value)} className="message" />
                            <div className="button-container">
                                <div style={{ cursor: loading ? 'default' : 'pointer', backgroundColor: loading ? 'lightgray' : '' }} onClick={() => handleSend(message)} className="button">{Translate.Send}</div>
                            </div>
                        </div>
                    </div>
                    <div className="imprint-container">
                        <div className="block">
                            {Util.splitAtLinebreaks(Translate.Imprint_1).map((item, key) => {
                                return (<div key={key} className="line">{item}</div>)
                            })}
                        </div>
                        <div className="block">
                            {Util.splitAtLinebreaks(Translate.Imprint_2).map((item, key) => {
                                return (<div key={key} className="line">{item}</div>)
                            })}
                        </div>
                        <div className="block">
                            {Util.splitAtLinebreaks(Translate.Imprint_3).map((item, key) => {
                                return (<div key={key} className="line">{item}</div>)
                            })}
                            <a className="mail" href="mailto:training@fitjo.de">training@fitjo.de</a>
                        </div>
                    </div>
                </div>
                <GenericPopup show={infoMessage[0] !== ''} message={infoMessage} setMessageState={setInfoMessage} />
            </div>
        </>
    )
}

export default FitjoInfo;