import { VideoType, SaveType } from "./utils/types";
import { PointsType } from "./api/models/points";

export const Translate = {

    //Login
    Login: 'Log dich ein',
    MagicLinkToMyMail: 'Magic Link an meine Email versenden',
    Email: 'Email',
    Password: 'Passwort',
    SendMagicLink: 'Magic Link senden',
    LoginWithMail: 'Log in mit Email',
    ConfirmPrivacyPolicy: 'Mit dem einloggen willigst du unseren Datenschutz-Bestimmungen ein.',
    ForgotPassword: 'Passwort vergessen?',
    AssignNewPassword: 'Passwort neu wählen',
    RepeatPassword: 'Passwort wiederholen',
    BackToLogin: 'Zurück zum Login',
    Next: 'Weiter',
    Or: 'Oder',
    InvalidEmail: 'Entschuldige, diese Email Adresse ist nicht gültig.',
    CheckMailAndPW: 'Login nicht erfolgreich. Bitte überprüfe deine e-Mail Adresse und dein Passwort.',
    NotRegistered: 'Noch nicht registriert? Jetzt starten.',
    InvalidMail: 'Keine gültige Email Adresse',
    EmailSend: 'Email wurde verschickt',
    EmailNotFound: 'Email wurde nicht gefunden',

    //Register
    Register: 'Registrier dich',
    InviteKey: 'Einladungscode',
    RegisterConfirmPrivacyPolicy: 'Mit der Registrierung willigst du unseren Datenschutz-Bestimmungen ein.',
    AlreadyRegistered: 'Bereits registriert? Jetzt einloggen.',
    FullName: 'Name',
    InvalidInviteKey: 'Der Einladungscode ist leider nicht korrekt.',

    //Forgot PW

    RequestForgotPWEmail: 'Passwort zurücksetzen',
    ForgotPWEmailSent: 'Wir haben dir eine E-mail zum zurücksetzen deines Passworts geschickt.',
    //Home
    Survey: 'Übersicht',
    Training: 'Training',
    TrainingWebtext: 'Diese Workouts helfen dir dabei an Stärke zu gewinnen',
    Nutrition: 'Ernährung',
    NutritionWebtext: 'Die richtige Ernährung hilft dir dabei an dein Ziel zu kommen',
    Meditation: 'Meditation',
    MeditationWebtext: 'Durch das Meditieren schaffst du dir eine Auszeit',
    Business: 'Business',
    Home: 'Home',
    Prevention: 'Prävention',
    Footsteps: 'Schritte',
    Standing: 'Stehend',
    SedentaryCar: 'Sitzend im Auto',
    SedentaryDesk: 'Sitzend am Schreibtisch',
    Breakfast: 'Frühstück',
    ColdFood: 'Kalte Speisen',
    WarmFood: 'Warme Speisen',
    Lunch: 'Mittagessen',
    Supper: 'Abendessen',
    Snacks: 'Snacks',
    SeeMore: 'Mehr',


    //Profile
    Account: 'Account',
    MyProfil: 'Mein Profil',
    Challenge: 'Challenge',
    Calendar: 'Kalender',
    ThatsMe: 'Das bin ich, ',
    Name: 'Name',
    FirstName: 'Vorname',
    Startdate: 'Startdatum',
    Score: 'Punktestand',
    MonthlyScore: 'Monatspunkte',
    ChallengeScore: 'Challengepunkte',
    Total: 'Gesamt',
    MyPoints: 'Meine Punkte',
    GeneralPoints: 'Gesamtpunkte',
    EditProfile: 'Profil ändern',
    YourEmail: 'Deine Email',
    YourName: 'Dein Name',
    DateOfBirth: 'Dein Geburtstag',
    Gender: 'Dein Geschlecht',
    DeleteAccount: 'Account löschen',
    ProfileImage: 'Profilbild',
    ChangeProfileImage: 'Profilbild ändern',
    DeleteProfileImage: 'Profilbild löschen',
    UpdateProfileImage: 'Profilbild erfolgreich geändert',
    UpdateProfile: 'Profil erfolgreich geändert',
    DeleteAccountQuestion: 'Bist du sicher dass du deinen Account löschen möchtest?',
    DeleteAccounYes: 'Ja, bitte löschen',
    MinutesBefore: 'Minuten vorher',
    HoursBefore: 'Stunden vorher',

    //Challenge
    ChallengeInfo: 'Hier findest Du Deine Challenges. Klicke auf die Challenge um teilzunehmen und wähle aus, ob die anderen Teilnehmer Deine Punkte sehen dürfen oder Du anonym bleiben möchtest. Bei der Teilnahme an einer Challenge veröffentlichst Du automatisch die Punkte an den Ersteller der Challenge. Wie Du diese gesammelt hast ist dabei nicht ersichtlich.',
    CompanyChallenge: 'Firmenchallenge',
    StepsChallenge: 'Schrittchallenge',
    Participation: 'Teilnahme',
    Participate: 'Teilnehmen',
    EndParticipation: 'Teilnahme beenden',
    Ranking: 'Rangliste',
    Anonym: 'Anonym',
    ParticipateAnonym: 'Möchtest du anonym teilnehmen?',
    EndParticipationQuestion: 'Möchtest du die Teilnahme wirklich beenden?',
    YesNext: 'Ja, weiter',
    Congratulations: 'Herzlichen Glückwunsch',
    ChallengeParticipationInfo: (name: string) => `Du nimmst nun an "${name}" teil. Viel Erfolg beim Punkte sammeln. Starte jetzt mit Deinem ersten Training oder einer Meditation.`,
    RankingError: 'Entschuldige, das Ranking kann gerade nicht geladen werden.',

    //Calendar
    AddEevnt: 'Ereignis hinzufügen',
    EditEvent: 'Ereignis ändern',
    Save: 'Speichern',
    Start: 'Start',
    End: 'Ende',
    Reminder: 'Erinnerung',
    EventMoment: 'Zum Erinnerungszeitpunkt',
    Minutes: 'Minuten',
    Hour: 'Stunde',
    Hours: 'Stunden',
    Day: 'Tag',
    Days: 'Tage',
    Week: 'Woche',
    before: 'vorher',
    BusinessTrainingsWeek: 'Business Trainings diese Woche',
    NoTraining: 'Noch kein Training',
    EventError: 'Entschuldige, deine Ereignisse können gerade nicht geladen werden.',
    DeleteEventSuccess: 'Das Ereignis wurde gelöscht.',
    DeleteEventError: 'Entschuldige, das Ereignis kann gerade nicht gelöscht werden.',
    AddEventError: 'Enschuldige, das Ereignis kann gerade nicht gespeichert werden.',
    SystemCalendarEvent: 'ACHTUNG: Dieser Eintrag wurde automatisch erstellt und darf nur über die Fitjo-App bearbeitet werden',

    //Fitjo
    HiThatsMe: 'Hi, das bin ich!',
    Discounts: 'Rabatte',
    DiscountCode: 'Rabattcode',
    IamJoanna: 'Hi, ich bin Joanna',
    About_1: `Mit 18 Jahren bin ich in der Fitnessbranche gestartet und habe gemerkt, dass es meine Passion ist, Menschen dabei zu unterstützen gesünder zu leben und Ihre Ziele zu erreichen.\n\nSchon während meines dualen Studiums im Bereich Fitnessökonomie musste ich feststellen, dass das Bewusstsein für Gesundheit durch Bewegung, eine gesunde und ausgewogene Ernährung sowie mentale Fitness noch viel zu wenig verbreitet ist.`,
    About_2: 'Mein Ziel steht fest: Menschen zu helfen, ein gesundes Verhalten & Bewusstsein zu leben und so die Lebensqualität zu steigern!',
    Fitjo_Text: `Jeder Mensch ist individuell. Und deshalb sollte auch die Gesundheitsförderung individuell sein. 
Mit FITJO findest Du Deine ganz individuelle Gesundheitsförderung. 
    
Wir bieten verschiedene Möglichkeiten, die eigene Gesundheit zu fördern. 
Ob Krafttraining, Schritte sammeln, meditieren oder gesund kochen. 

Challenge Dich und lasse Dich belohnen für Deine individuelle Gesundheit. 
    
Unser Ziel steht fest: Dafür zu sorgen, dass Du gesünder, leichter und wohlfühlender durchs Leben gehst.`,
    ContactRequest: 'Kontaktanfrage',
    CompanyName: 'Firmenname',
    Message: 'Nachricht',
    Send: 'Senden',
    FitjoInfo: 'Hier findest Du Infos und Kontaktdaten von FITJO sowie Rabatte bei anderen Gesundheitsfördernden Firmen, die Du dauerhaft nutzen kannst. Wenn Du Wünsche und Vorschläge zur Erweiterung oder Verbesserung hast freuen wir uns von Dir zu hören.',
    ContactRequestSend: 'Danke. Wir haben deine Kontaktanfrage erhalten.',
    ContactRequestError: 'Entschuldige, wir konnten die Kontaktanfrage leider nicht senden',
    Imprint_1: `JP Consulting UG (haftungsbeschränkt)\nGeschäftsführende Gesellschafterin: Joanna Palmowski\nAm Homberg 13\n45529 Hattingen`,
    Imprint_2: `Steuernummer: 323/5714/0988\nGründungsdatum: 29.07.2021\nHandelsregister: HRB 31597 (Amtsgericht Essen)\nOH-Nummer: C-23535004`,
    Imprint_3: `Kontakt:\nJoanna Palmowski`,

    //Company
    MyCompany: 'Mein Unternehmen',
    Contact: 'Ansprechpartner',
    Events: 'Angebote und Events',

    //News
    News: 'News',
    NewsInfoText: 'Die News helfen Dir Dein Wissen über Gesundheit und Fitness zu erweitern und geben Dir Tipps und Tricks zur Umsetzung im Alltag.',
    TrainingExercise: 'Training & Bewegung',
    Office: 'Office',
    General: 'Allgemein',


    //Training
    TrainingBusiness: `Dieses Training kannst Du optimal als Pause während Deiner Arbeitszeit durchführen. Du brauchst Dich nicht umzuziehen und auch keine besonderen Geräte. Wähle einfach, ob Du das Training stehend, sitzend oder im Auto sitzend durchführst. Das Punktesammeln mit einem Business Training ist 1 Mal am Tag möglich.`,
    TrainingHome: `Diese Trainings kannst Du zu Hause, im Hotelzimmer oder an einem anderen Ort Deiner Wahl durchführen. Wähle Deine Kategorie und anschließend Dein gewünschtes Training.\nEmpfohlene Kleidung: bequeme (Sport-) Kleidung, feste Sportschuhe oder Barfuß.\nHalte immer Wasser zum Trinken, ein Handtuch und eine bequeme Unterlage (z.B. eine Sportmatte) bereit.\nAbsolviere das Training bis zum Schluss, um Deine Punkte zu sammeln.`,
    TrainingToday: 'Dein heutiges Training',
    TrainingInfo: 'Dauer: 7 Minuten. Drücke auf Start, wenn Du Bereit bist.',
    TrainingInfoPoints: 'Absolviere das Training bis zum Schluss, um Deine Punkte zu sammeln.',
    TrainingInfoPointsAlreadyCollected: 'Du hast heute schon Punkte mit dem Business Training gesammelt. Du kannst das Training aber trotzdem absolvieren.',
    WholeBodyTraining: 'Ganzkörpertraining',
    Backfit: 'Rückenfit',
    Mobility: 'Mobility',
    Hiit: 'HIIT',
    ALB: 'Bauch, Beine, Po',
    UPPER_BODY: 'Oberkörper',
    NextVideo: 'Nächstes Video',
    PlayAgain: 'Erneut abspielen',
    Length: 'Dauer',
    Points: 'Punkte',
    AddPointsError: 'Entschuldige, wir konnten die Punkte deinem Konto nicht hinzufügen.',
    VideoError: 'Entschuldige, das Video kann gerade nicht abgespielt werden',

    //Meditation
    MeditationInfo: 'Meditationen helfen Dir im Alltag neue Energie zu sammeln, zur Ruhe zu kommen und Dich auf Dich zu fokussieren. Nutze Sie, um mit neuer Kraft in den Tag zu starten, Deine Pause gut zu nutzen oder um vor dem Schlafen gehen zur Ruhe zu kommen. Die jeweiligen Punkte der Meditation erhältst Du nach dem Abschluss.',

    //Footsteps
    FootstepsOverview: 'Schritt Übersicht',
    FootstepsToday: 'Heute gelaufen',
    WeekAverage: 'Durchschnitt Woche',
    PointsOverview: 'Punkte Übersicht',
    YourPoints: 'Deine Punkte',
    TransferPoints: (steps: number, points: number) => `Du hast ${steps} Schritte gemacht, dafür wurde${points > 1 && 'n'} dir ${points} Punkt${points > 1 && 'e'} auf dein Konto gutgeschrieben.`,

    //Favorites
    Favorites: 'Favoriten',
    FavoritesInfo: 'Deine Favoriten, die Du Dir gemerkt hast. So kannst Du durch einen schnellen Klick Deine liebsten Trainings, Rezepte und News Artikel finden!',

    //Feedback
    Super: 'SUPER',
    FeedbackWorkoutText: (points: number) => `Deinem Konto wurden ${points} Punkte gutgeschrieben.`,
    NoThanks: 'Nein, danke',
    StartWorkout: 'Start Workout',
    SaveWorkout: 'Workout speichern',
    SaveWorkoutText: 'Du kannst dieses Workout zu deinen Favoriten hinzufügen, um es jederzeit super schnell aufrufen zu können.',
    Break: 'Abbrechen',
    SaveWorkoutName: 'Gib dem Workout einen Namen um es leichter wieder zu finden.',
    DailyPointsLimitReached: (pointsType: PointsType) => `Glückwunsch, Du hast die maximale Punktzahl für ${getPointsType(pointsType)} von ${getPointsLimit(pointsType)} Punkten am Tag erreicht.`,

    //Recipes
    Ingredients: 'Zutaten',
    Steps: 'Schritte',

    //Video
    BreakVideo: (type: VideoType) => `${getVideoType(type)} abbrechen`,
    StopVideo: (type: VideoType) => `${getVideoType(type)} beenden`,
    ContinueVideo: (type: VideoType) => `${getVideoType(type)} fortsetzen`,

    //Tutorial
    Step_1_Headline: "Willkommen bei FITJO!",
    Step_2_Headline: "Dein individueller Weg zur Gesundheit",
    Step_3_Headline: "Kalender",
    Step_4_Headline: "Los gehts!",
    Step_1_Text: `Deine App für digitale Gesundheitsförderung.
Hier findest Du Training, Ernährung, Meditation und Motivation. Sammle Punkte, challenge Dich mit Deinen Kollegen und lass Dich für Deine Gesundheitsförderung belohnen.`,
    Step_2_Text: `Ganz egal ob Schritte, Training oder Meditation, finde Deinen individuellen Weg zur Gesundheit. `,
    Step_3_Text: "Durch die Kalenderfunktion kannst Du Deine Gesundheit ganz einfach planen und erinnert werden.",
    Step_4_Text: `Starte jetzt mit Deinem ersten Training. 
Viel Spaß!`,

    //Other
    Back: 'Zurück',
    Loading: 'Loading',
    OneMoment: 'Einen Moment bitte',
    SavedUnder: (type: SaveType) => `Gespeichert unter "${getSaveType(type)}"`,
    RemovedFrom: (type: SaveType) => `Entfernt aus "${getSaveType(type)}"`,
    TitleRemoved: (title: string) => `"${title}" aus Favoriten entfernt`,
    TitleAdded: (title: string) => `"${title}" zu Favoriten hinzugefügt`,
    RecipesError: 'Entschuldige, die Rezepte können gerade nicht geladen werden.',
    NoRecipes: 'Hier gibt es leider gerade keine passenden Rezepte',
    NewsError: 'Entschuldige, die News können gerade nicht geladen werden.',
    NoNews: 'Hier gibt es leider aktuell nichts neues.',
    DiscountsError: 'Entschuldige, die Rabatte können gerade nicht geladen werden.',
    NoDiscounts: 'Hier gibt es leider gerade keine passenden Rabatte',
    Search: 'Suche...',
    GeneralError: 'Entschuldige, das klappt leider gerade nicht.',
    CompanyError: 'Entschuldige, das Unternehmen kann gerade nicht geladen werden.',
    MeditationError: 'Entschuldige, die Meditationen können gerade nicht geladen werden',
    NoMeditations: 'Hier gibt es leider gerade keine Meditationen',
    NoTrainings: 'Hier gibt es leider gerade keine passenden Trainings',
    TrainingError: 'Entschuldige, die Trainings können gerade nicht geladen werden.',
    NoChallenges: 'Hier gibt es leider gerade keine passenden Challenges',
    ChallengesError: 'Entschuldige, die Challenges können gerade nicht geladen werden.',
    NoEvents: 'Hier gibt es leider gerade keine Angebote',
    EventsError: 'Entschuldige, die Angebote können gerade nicht geladen werden.',
    CouldNotRemoveFavorite: 'Entschuldige, das Element konnte nicht entfernt werden',
    CouldNotAddFavorite: 'Entschuldige, das Element konnte nicht hinzugefügt werden',
    No: 'Nein',
    Yes: 'Ja',
    Ok: 'OK',
    InactiveAccount: 'Dein Account wurde vorübergehend gesperrt. Bitte wende dich an die verantwortliche Person in deinem Unternehmen.',
    GoogleFitExplanation: 'Google Fit ist eine offene Plattform, mit der Sie Ihre Fitnessdaten von mehreren Apps und Geräten aus kontrollieren können. Durch das Verbinden von Fitjo und Google Fit werden Ihre gesammelten Schritte automatisch in Fitjo Punkten gutgeschrieben. Sie können die Verbindung mit Google Fit jederzeit unter "Account" trennen.',
    GoogleFitModalTitle: 'Google Fit aktivieren',
    GoogleFitStepsErrorsTitle: 'Synchronisierungsfehler!',
    GoogleFitStepsError: 'Es trat ein Fehler mit der Google Fit Synchronisation auf, bitte logge Dich einmal aus Fitjo aus und wieder ein. Dann erscheint der Google Fit Dialog erneut.',
    GoogleFitStepsNotAllowed: 'Fitjo ist zur Zeit nicht berechtigt, Daten aus Google Fit zu synchronisieren. Um den Dialog erneut anzuzeigen, logge Dich bitte aus Fitjo aus und wieder ein. Dann erscheint der Google Fit Dialog erneut.',
    GoogleFitNoStepsCase: `
Es trat ein Problem beim synchronisieren Deiner Schritte auf.
Bitte logge dich aus der App aus und prüfe ob Du deine Schritte in der GoogleFit App findest und logge dich erneut ein.
    `,


    //Months
    Jan: 'JAN',
    Feb: 'FEB',
    Mar: 'MAR',
    Apr: 'APR',
    Mai: 'MAI',
    Jun: 'JUN',
    Jul: 'JUL',
    Aug: 'AUG',
    Sep: 'SEP',
    Oct: 'OKT',
    Nov: 'NOV',
    Dec: 'DEZ',
    Jan_Full: 'Januar',
    Feb_Full: 'Februar',
    Mar_Full: 'März',
    Apr_Full: 'April',
    Mai_Full: 'Mai',
    Jun_Full: 'Juni',
    Jul_Full: 'Juli',
    Aug_Full: 'August',
    Sep_Full: 'September',
    Oct_Full: 'Oktober',
    Nov_Full: 'November',
    Dec_Full: 'Dezember'
}

function getSaveType(type: SaveType) {
    switch (type) {
        case 'meditation':
            return 'Meditation'
        case 'news':
            return 'News'
        case 'nutrition':
            return 'Ernährung'
        case 'training':
            return 'Training'
    }
}

function getVideoType(type: VideoType) {
    switch (type) {
        case 'meditation':
            return 'Meditation'
        case 'training':
            return 'Training'
    }
}

function getPointsType(type: PointsType) {
    switch (type) {
        case 'training_business':
            return "Training-" + Translate.Business;
        case 'training_home':
            return "Training-" + Translate.Home;
        case 'meditation':
            return Translate.Meditation;
        case 'steps':
            return Translate.Footsteps;
        default:
            return "Unbekannt";
    }
}

function getPointsLimit(type: PointsType) {
    switch (type) {
        case 'training_business':
            return 7;
        case 'training_home':
            return 60;
        case 'meditation':
            return 30;
        case 'steps':
            return -1;
        default:
            return -1;
    }
}