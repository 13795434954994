import { Route, Switch, useHistory } from "react-router";
import { Translate } from "../sdk/translate";
import { API as TrainingAPI } from '../sdk/api/endpoints/training';
import { useEffect, useState } from "react";
import { Training } from "../sdk/api/models/training";
import { messageType } from "../sdk/utils/types";

// Assets
import {
    ARROW_LEFT,
    MEDITATION_IMAGE_3
} from "../assets/exports";
import GenericPopup from "../components/GenericPopup";
import { FitjoLoader } from "../components/FitjoLoader";
import Video from "./Video";


const Meditation = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true)
    const [message, setMessage] = useState<[string, messageType]>(['', 'info'])
    const [meditationVideos, setMeditationVideos] = useState<Array<Training>>([]);

    useEffect(() => {
        TrainingAPI.getTraining('meditation').then((trainingArr) => {
            if (trainingArr.length !== 0) {
                setMeditationVideos(trainingArr)
            }
            else {
                throw Translate.NoTrainings
            }
        }).catch((err) => {
            console.log('Error', err)
            setMessage([Translate.NoTrainings, 'error'])
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <>
            <Switch>
                <Route path='/survey/meditation/:id' component={() => <Video {...props} videos={meditationVideos} />} />
                <Route render={() => {
                    return (
                        <div className="meditation-container">
                            <div className="header">
                                <>{Translate.Meditation}</>
                                <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                            </div>
                            <div className="text-header">{Translate.Meditation}</div>
                            <div className="text">{Translate.MeditationInfo}</div>
                            <div className="row">
                                {
                                    !loading ?
                                        meditationVideos.map((video, key) => {
                                            return (
                                                <div key={key} onClick={() => history.push('/survey/meditation/' + video.id)} style={{ backgroundImage: video.video_thumbnail ? `url(${video.video_thumbnail})` : `url(${MEDITATION_IMAGE_3})` }} className="element">
                                                    <span className="element-text">{video.name}</span>
                                                    <div className="stats">{Translate.Length}: {video.points} {Translate.Minutes} | {Translate.Points}: {video.points}</div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="loading-container">
                                            <div className="loader-size"><FitjoLoader /></div>
                                        </div>
                                }
                            </div>
                            <GenericPopup show={message[0] !== ''} message={message} setMessageState={setMessage} />
                        </div>
                    )
                }} />
            </Switch>

        </>
    );
}

export default Meditation;