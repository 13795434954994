import { Route, Switch, useHistory } from "react-router";
import { Translate } from "../sdk/translate";

// Assets
import { ARROW_LEFT, HOME1, HOME2, HOME3, HOME4, HOME5, } from "../assets/exports";


const Home = () => {
    const history = useHistory();

    return (
        <>
            <div className="home-container">
                <div className="header">
                    <>{Translate.Home}</>
                    <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                </div>
                <div className="text-header">Training Home</div>
                <div className="text">{Translate.TrainingHome}</div>
                <div className="row">
                    <div onClick={() => history.push('/survey/home/wholebody')} style={{ backgroundImage: `url(${HOME1})` }} className="element">{Translate.WholeBodyTraining}</div>
                    <div onClick={() => history.push('/survey/home/backfit')} style={{ backgroundImage: `url(${HOME2})` }} className="element">{Translate.Backfit}</div>
                    <div onClick={() => history.push('/survey/home/mobility')} style={{ backgroundImage: `url(${HOME3})` }} className="element">{Translate.Mobility}</div>
                    <div onClick={() => history.push('/survey/home/hiit')} style={{ backgroundImage: `url(${HOME4})` }} className="element">{Translate.Hiit}</div>
                    <div onClick={() => history.push('/survey/home/alb')} style={{ backgroundImage: `url(${HOME5})` }} className="element">{Translate.ALB}</div>
                </div>
            </div>
        </>
    );
}

export default Home;