import { Translate } from "../sdk/translate";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { API as UserAPI } from '../sdk/api/endpoints/user';
import { Company } from "../sdk/api/models/company";

// Assets
import {
    ARROW_LEFT,
    CONTACT_IMAGE_1,
    USER_ICON,
    MAIL_ICON,
    CALL_ICON,
} from "../assets/exports";
import { FitjoLoader } from "../components/FitjoLoader";
import { messageType } from "../sdk/utils/types";
import GenericPopup from "../components/GenericPopup";

const Contact = () => {
    const history = useHistory()
    const [loading, setLoading] = useState<boolean>(true)
    const [company, setCompany] = useState<Company>()
    const [message, setMessage] = useState<[string, messageType]>(['', 'info'])

    useEffect(() => {
        UserAPI.getCompany().then((company) => {
            setCompany(company)
            setLoading(false)
        }).catch((err) => {
            console.log('Error', err)
            setMessage([Translate.GeneralError, 'error'])
        })
    }, [])

    return (
        <>
            <div className="contact-container">
                <img className="image" src={CONTACT_IMAGE_1} alt="" />
                <div className="content-container">
                    <div className="header">
                        <>{Translate.Contact}</>
                        <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                    </div>
                    {
                        !loading ?
                            <>

                                <div className="contact-details">
                                    {company?.contact && <div className="row">
                                        <img className="icon" src={USER_ICON} alt="" />
                                        <div className="text">{company!.contact}</div>
                                        <div style={{ minWidth: "2rem" }}></div>
                                    </div>}
                                    {company?.email && <a href={"mailto:" + company!.email} className="row">
                                        <img className="icon" src={MAIL_ICON} alt="" />
                                        <div className="link">{company!.email}</div>
                                        <div style={{ minWidth: "2rem" }}></div>
                                    </a>}
                                    {company?.website && <a target="_blank" href={"https://" + company.website} className="row">
                                        <img className="icon" src={MAIL_ICON} alt="" />
                                        <div className="link">{company!.website}</div>
                                        <div style={{ minWidth: "2rem" }}></div>
                                    </a>}
                                    {company?.phone_number && <a href={"tel:" + company!.phone_number} className="row">
                                        <img className="icon" src={CALL_ICON} alt="" />
                                        <div className="link">{company!.phone_number}</div>
                                        <div style={{ minWidth: "2rem" }}></div>
                                    </a>}
                                </div>
                            </>
                            :
                            <div className="fitojo-loader">
                                <FitjoLoader />
                            </div>
                    }
                </div>
                <GenericPopup show={message[0] !== ''} message={message} setMessageState={setMessage} />
            </div>
        </>
    );
}

export default Contact;