import { BaseModel } from "./base";
import { JsonProperty } from "../requests/jsonparser";

export class RecipeIngredient extends BaseModel {

    @JsonProperty({ type: 'number?' })
    amount?: number;

    @JsonProperty({ type: 'string?' })
    unit?: string;

    @JsonProperty({ type: 'string' })
    name: string;

}