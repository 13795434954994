export const Colors = {
    BgBlue: '#415056',
    BgBlue_70: '#415057B3',
    Green: '#9FC0A3',
    Green_30: '#9FC0A34D',
    Light_Green: '#D4DFD2',
    Grey: '#D7D7D7',
    Grey2: '#7B7B7B',
    LightGrey: '#E5E5E5',
    MiddleGrey: '#C7C7C7',
    Dark_Grey: '#ABABAB',
    White: '#FFFFFF',
    White_0: '#FFFFFF00',
    White_70: '#FFFFFFB3',
    Light_Black: '#464646',
    Black: '#313131',
    Dark_White: '#EEEFF3',
    Black_60: '#00000099',
    Black_40: '#00000066',
    Black_10: '#0000001A',
    LightRed: '#FEEFF0',
    Red: '#F71A2F',
    LightBlue: '#EEF5FF',
    Blue: '#1170FF',
    Bronze: '#BF8970',
    Silver: '#8A9597',
    Gold: '#D4AF37'
}