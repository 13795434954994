import { BaseModel } from "./base";
import { JsonProperty } from "../requests/jsonparser";

export class RecipeStep extends BaseModel {

    @JsonProperty({ type: 'number' })
    step: number;

    @JsonProperty({ type: 'string' })
    description: string;

}