import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import { Recipe } from "../sdk/api/models/recipe"
import { Translate } from "../sdk/translate";
import { RecipesAPI } from "../sdk/api/endpoints";
import { FitjoLoader } from "../components/FitjoLoader";
import { RecipeStep } from "../sdk/api/models/recipeStep";
import { RecipeIngredient } from "../sdk/api/models/recipeIngredient";
import GenericPopup from "../components/GenericPopup";
import { APIErrorType } from "../sdk/api/errors";
import { messageType } from "../sdk/utils/types";

// Assets
import { ARROW_LEFT, BOOKMARK, BOOKMARK_FILLED, CHECK, TIMES } from "../assets/exports";

type Props = {
    recipes: Array<Recipe>
};
type NutritionSingleProps = Props & RouteComponentProps<{}>;

const NutritionSingle = (props: NutritionSingleProps) => {
    const [message, setMessage] = useState<[string, messageType]>(['', 'info']);
    const [loading, setLoading] = useState<boolean>(true)
    const [favLoading, setFavLoading] = useState<boolean>(false);
    const params = useParams<{ id, type }>()
    const [type, setType] = useState<string>()
    const [recipe, setRecipe] = useState<Recipe>()
    const history = useHistory()
    const [isFavorite, setFavorite] = useState<boolean>(false)
    const [showPopup, setShowPopup] = useState<boolean>(false)

    const handleFav = () => {
        setFavLoading(true)
        if (isFavorite) {
            RecipesAPI.removeFavorite(recipe!.id!).then(() => {
                setFavorite(false);
                setFavLoading(false);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 650)
            }).catch((err) => {
                setFavLoading(false);
                setMessage([Translate.CouldNotRemoveFavorite, 'error']);
            })
        }
        else {
            RecipesAPI.addFavorite(recipe!.id!).then(() => {
                setFavorite(true);
                setFavLoading(false);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 650)
            }).catch((err) => {
                setFavLoading(false);
                setMessage([Translate.CouldNotAddFavorite, 'error']);
            })
        }
    }

    useEffect(() => {
        props.recipes.every((recipe) => {
            if (recipe.id === params.id) {
                setRecipe(recipe)
                checkIsFavourite()
                switch (params.type) {
                    case 'breakfast':
                        setType(Translate.Breakfast)
                        break
                    case 'cold':
                        setType(Translate.ColdFood)
                        break
                    case 'warm':
                        setType(Translate.WarmFood)
                        break
                    case 'snack':
                        setType(Translate.Snacks)
                        break
                }
                setLoading(false);
                return false;
            }
            return true;
        })
    }, [props.recipes, params.id, params.type])

    const checkIsFavourite = () => {
        setFavLoading(true)
        RecipesAPI.getFavorites().then((favorites) => {
            favorites.every((favorite) => {
                if (favorite.id === params.id) {
                    setFavorite(true)
                    return false;
                }
                return true;
            })
            setFavLoading(false)
        }).catch((err: APIErrorType) => {
            console.log('Error', err)
            setMessage([Translate.GeneralError, 'error'])
        })
    }

    const stepCompare = (a: RecipeStep, b: RecipeStep) => {
        if (a.step < b.step)
            return -1
        if (a.step > b.step)
            return 1
        return 0
    }

    const ingredientCompare = (a: RecipeIngredient, b: RecipeIngredient) => {
        if (a.amount && !b.amount)
            return -1
        if (!a.amount && b.amount)
            return 1
        return 0
    }

    return (
        <>
            {
                !loading ?
                    <div className="nutrition-single-container">
                        <div className="header-image" style={{ backgroundImage: `url(${recipe?.img_url})` }} />
                        <div className="content">
                            <div className="header">
                                <>{type}</>
                                <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                                <div className="fav-container">
                                    {
                                        favLoading ? <FitjoLoader /> : <img onClick={() => handleFav()} className="bookmark" src={isFavorite ? BOOKMARK_FILLED : BOOKMARK} alt="" />
                                    }
                                </div>
                            </div>
                            <div className="recipe-name">{recipe?.name!}</div>
                            <div className="recipe-description">{recipe?.description}</div>
                            <div className="section-header">{Translate.Ingredients}</div>
                            <div className="ingredients-container">
                                {/* map ingredients */}
                                {
                                    recipe?.ingredients.sort(ingredientCompare).map((ingredient, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <div className="measurements">{ingredient.amount ? (ingredient.amount > 0 ? ingredient.amount : '') : ''} {ingredient.unit}</div>
                                                <div className="ingredient">{ingredient.name}</div>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                            <div className="section-header">{Translate.Steps}</div>
                            <div className="steps-grid">
                                {/* map steps */}
                                {
                                    recipe?.steps.sort(stepCompare).map((step, key) => {
                                        return (
                                            <>
                                                <div className="step">{('0' + (step.step + 1)).slice(-2)}</div>
                                                <div className="description">{step.description}</div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ top: showPopup ? "7rem" : "0", opacity: showPopup ? 1 : 0 }} className="popup-container">
                            <div className="icon-container">
                                <img className="icon" src={isFavorite ? CHECK : TIMES} alt="" />
                            </div>
                            <div className="text">{isFavorite ? Translate.SavedUnder('nutrition') : Translate.RemovedFrom('nutrition')}</div>
                        </div>
                        <GenericPopup show={message[0] !== ''} message={message} setMessageState={setMessage} />
                    </div>
                    :
                    <div className="loading-page">
                        <div className="loader-container">
                            <FitjoLoader />
                        </div>
                    </div>
            }
        </>
    )
}

export default NutritionSingle