export enum APIErrorType {
    InvalidParameters = "InvalidParametersError",
    //InvalidSubscriptionError = "InvalidSubscriptionError",
    AlreadyExists = "ResourceAlreadyExists",
    //InvalidGoogleToken = "InvalidGoogleToken",
    //InvalidFacebookToken = "InvalidFacebookToken",
    InvalidLogin = "InvalidLoginError",
    ResourceNotFound = "ResourceNotFound",
    NotAccessibleError = "NotAccessibleError",
    UnknownError = "UnknownError",
    NetworkError = "NetworkError",
    MissingAuthorizationError = "MissingAuthorizationError",
    //SessionNotFoundError = "invalid_jwt_token",
    //MemberNotFound = "MemberNotFoundError",
    VerificationError = "VerificationTokenInvalidError",
    //MemberNameNotMatch = "MemberNameNotMatchError",
    //MemberAlreadyExists = "MemberAlreadyExists",
    InactiveAccountError = "InactiveAccountError",
    LimitReachedError = "LimitReachedError"
}