import { useEffect, useState } from "react";
import { Route, RouteComponentProps, Switch, useHistory, useParams } from "react-router";
import { NEWS_TYPE } from "../sdk/utils/types";
import { Translate } from "../sdk/translate";
import { API as NewsAPI } from '../sdk/api/endpoints/news';
import { News } from "../sdk/api/models/news";
import { FitjoLoader } from "../components/FitjoLoader";

// Assets
import { ARROW_LEFT, NEWS_IMAGE_1 } from "../assets/exports";
import NewsSingle from "./NewsSingle";

type Props = {};
type NewsCategoryProps = Props & RouteComponentProps<{}>;

const NewsCategory = (props: NewsCategoryProps) => {
    const history = useHistory()
    const [category, setCategory] = useState<string>()
    const params = useParams<{ category }>()
    const [loading, setLoading] = useState<boolean>(true)
    const [news, setNews] = useState<Array<News>>([])

    useEffect(() => {
        switch (params.category as NEWS_TYPE) {
            case 'nutrition':
                setCategory(Translate.Nutrition)
                break
            case 'training':
                setCategory(Translate.TrainingExercise)
                break
            case 'office':
                setCategory(Translate.Office)
                break
            case 'general':
                setCategory(Translate.General)
                break
        }

        NewsAPI.getNews(params.category).then((newsArr) => {
            setNews(newsArr)
        }).catch((err) => {
            console.log("Error", err)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <>
            <Switch>
                <Route path='/news/:category/:id' component={() => <NewsSingle {...props} news={news} />} />
                <Route render={() => {
                    return (
                        <div className="news-category-container">
                            <div className="header">
                                <>{category}</>
                                <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                            </div>
                            <div className="row">
                                {
                                    !loading ?
                                        (
                                            news.length !== 0 ?
                                                news.map((article, key) => {
                                                    return (
                                                        <div key={key} onClick={() => history.push('/news/' + article.type + '/' + article.id)} style={{ backgroundImage: `url(${article.img_url})` }} className="element"><span className="element-text">{article.headline}</span></div>
                                                    )
                                                })
                                                :
                                                <>
                                                    <div style={{ cursor: 'default' }} className="element"><span className="element-text">{Translate.NoNews}</span></div>
                                                </>
                                        )
                                        :
                                        <div className="element">
                                            <div className="loader-container">
                                                <FitjoLoader />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    )
                }} />
            </Switch>
        </>
    )
}

export default NewsCategory;