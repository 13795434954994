import { Translate } from "../sdk/translate";
import { useHistory } from "react-router";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { UserContext } from "../context/user";
import { API as PointsAPI } from '../sdk/api/endpoints/points';
import { API as UserAPI } from '../sdk/api/endpoints/user';
import { FitjoLoader } from "../components/FitjoLoader";
import moment from "moment";
import validator from 'validator';
import { UploadOutlined } from '@ant-design/icons';

// Assets
import { ARROW_LEFT, EDIT_ICON, NO_USER_ICON, CHECK, EDIT_USER_ICON, TIMES } from "../assets/exports";
import { messageType } from "../sdk/utils/types";
import GenericPopup from "../components/GenericPopup";
import { Button, Upload } from "antd";
import { Session } from "../sdk/api/requests/request";



const Account = () => {
    const user = useContext(UserContext)
    const history = useHistory();
    const [name, setName] = useState<string>(user.full_name)
    const [mail, setMail] = useState<string>(user.email)
    const [loadingUpdateAccount, setLoadingUpdateAccount] = useState<boolean>(false)
    const [editMode, setEditMode] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showPopMenu, setShowPopupMenu] = useState<boolean>(false);
    const [pointsSum, setPointsSum] = useState<number>(0);
    const [loadingPointsSum, setLoadingPointsSum] = useState<boolean>(true)
    const [pointsMonth, setPointsMonth] = useState<number>(0);
    const [loadingPointsMonth, setLoadingPointsMonth] = useState<boolean>(true)
    const [loadingAccountDelete, setLoadingAccountDelete] = useState<boolean>(false)
    const [message, setMessage] = useState<[string, messageType]>(['', 'info'])
    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState<boolean>(false)

    const Uploader = (props) => {
        const [loading, setLoading] = useState(false)

        return (
            <Upload beforeUpload={() => setLoading(true)} showUploadList={false} maxCount={1} accept='image/*' action={(file) => {
                return UserAPI.uploadProfileImage(file).then((user) => {
                    Session.getUserUpdatedHandler()(user);
                    setShowPopupMenu(false);
                    setEditMode(false);
                    return ''
                }).finally(() => {
                    setMessage([Translate.UpdateProfileImage, 'success'])
                    setLoading(false)
                })
            }}>
                <Button className="upload-button" loading={loading} icon={<UploadOutlined />}>{Translate.ChangeProfileImage}</Button>
            </Upload>
        );
    };

    useEffect(() => {
        PointsAPI.countPoints('month').then((points) => {
            setPointsMonth(points)
        }).finally(() => {
            setLoadingPointsMonth(false)
        })
        PointsAPI.countPoints().then((points) => {
            setPointsSum(points)
        }).finally(() => {
            setLoadingPointsSum(false)
        })
    }, [])

    const updateAccount = () => {
        if (validator.isEmail(mail) && name.trim() !== '') {
            setLoadingUpdateAccount(true)
            UserAPI.updateProfile(mail, name).then(() => {
                setShowPopupMenu(false);
                setEditMode(false);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 650)
            }).catch((err) => {
                console.log('Error', err)
                setMessage([Translate.GeneralError, 'error'])
            }).finally(() => {
                setLoadingUpdateAccount(false)
            })
        }
        else {
            setMessage([Translate.InvalidEmail, 'error'])
        }
    }

    const handleDeleteAccount = (confirmedDelete: boolean) => {
        if (confirmedDelete) {
            setLoadingAccountDelete(true)
            UserAPI.deleteAccount().then(() => {
                setLoadingAccountDelete(false);
                handleLogout()
            }).catch((err) => {
                console.log('Error', err)
                setMessage([Translate.GeneralError, 'error'])
                setLoadingAccountDelete(false);
            })
        }
        else {
            setShowConfirmDeletePopup(true)
        }
    }

    const handleLogout = () => {
        Session.setSession(undefined)
        localStorage.setItem('user', '')
        Session.getUserUpdatedHandler()(undefined as any)
    }

    return (
        <>
            <div className="account-container">
                <div className="header">
                    <>{Translate.Account}</>
                    <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                </div>
                <div className="content-container">
                    <div className="shadow">
                        <div className="top-half">
                            <div className="profile-pic-container">
                                <div className="profil-pic" style={{ backgroundImage: `url(${user.profile_image_url ? user.profile_image_url : NO_USER_ICON})` }} />
                                <img onClick={() => setShowPopupMenu(true)} style={{ display: editMode ? "block" : "none" }} className="edit-profile-button" src={EDIT_USER_ICON} alt="" />
                            </div>
                            <input style={{ display: editMode ? 'block' : 'none' }} className="input" placeholder={Translate.YourEmail} type="text" value={mail} onChange={(event) => setMail(event.target.value)} />
                            {!editMode ? <div className="name">{name}</div> : <input className="input" placeholder={Translate.YourName} type="text" value={name} onChange={(event) => setName(event.target.value)} />}
                            <div style={{ display: !editMode ? "block" : "none" }} className="date">{`Gestartet am ${moment(user.created_at).format('DD.MM.YYYY')}`}</div>
                            <div style={{ display: editMode ? "block" : "none" }} onClick={() => handleDeleteAccount(false)} className="delete-button">{Translate.DeleteAccount}</div>
                            <div style={{ display: !editMode ? "block" : "none" }} onClick={() => handleLogout()} className="delete-button">Logout</div>
                            <img style={{ display: !editMode ? "block" : "none" }} onClick={() => setEditMode(true)} className="edit-button" src={EDIT_ICON} alt="" />
                        </div>
                        <div className="bottom-half">
                            <div style={{ display: !editMode ? "flex" : "none" }} className="points-header">{Translate.MyPoints}:</div>
                            <div style={{ display: !editMode ? "flex" : "none" }} className="row">
                                <div className="element border-right">
                                    <div className="element-header">{Translate.MonthlyScore}</div>
                                    {!loadingPointsMonth ? <div className="points">{pointsMonth}</div> : <div className="loader-container"><FitjoLoader /></div>}
                                </div>
                                <div className="element">
                                    <div className="element-header">{Translate.GeneralPoints}</div>
                                    {!loadingPointsSum ? <div className="points">{pointsSum}</div> : <div className="loader-container"><FitjoLoader /></div>}
                                </div>
                            </div>
                            <Button loading={loadingUpdateAccount} onClick={() => updateAccount()} style={{ display: editMode ? "block" : "none" }} className="save-button">{Translate.Save}</Button>
                        </div>
                    </div>
                </div>
                <div style={{ top: showPopup ? "7rem" : "0", opacity: showPopup ? 1 : 0 }} className="popup-container">
                    <div className="icon-container">
                        <img className="icon" src={CHECK} alt="" />
                    </div>
                    <div className="text">{Translate.UpdateProfile}</div>
                </div>
                <div style={{ opacity: showPopMenu ? 1 : 0, display: showPopMenu ? 'block' : 'none' }} className="popup-menu-container">
                    <div className="relative">

                        <div className="header">{Translate.ProfileImage}</div>
                        <Uploader setMessage={setMessage} />
                        <img onClick={() => setShowPopupMenu(false)} className="close-button" src={TIMES} alt="" />
                    </div>
                </div>
                <div style={{ opacity: showConfirmDeletePopup ? 1 : 0, display: showConfirmDeletePopup ? 'block' : 'none' }} className="popup-menu-container">
                    <div className="relative">
                        {
                            !loadingAccountDelete ?
                                <>
                                    <div className="header">{Translate.DeleteAccountQuestion}</div>
                                    <div onClick={() => handleDeleteAccount(true)} className="option">{Translate.DeleteAccounYes}</div>
                                </>
                                :
                                <div className="loader-container">
                                    <FitjoLoader />
                                </div>
                        }
                        <img onClick={() => setShowConfirmDeletePopup(false)} className="close-button" src={TIMES} alt="" />
                    </div>
                </div>
                <GenericPopup show={message[0] !== ''} message={message} setMessageState={setMessage} />
            </div>
        </>
    )
}

export default Account;