import { CalendarEvent, EventType } from "../models/calendarEvent";
import { APIVersion, AuthenticatedRequest, HTTPMethod } from "../requests/request"

export class API {

    /**
     * Get the Calendar Events
     */
    public static getCalendarEvents(): Promise<CalendarEvent[]> {
        let apiReqest = new AuthenticatedRequest<CalendarEvent>('/calendar/event', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send(CalendarEvent).then((response) => {
            return <CalendarEvent[]>response.data
        })
    }

    /**
     * 
     * @param body BodyParameters
     */
    public static addCalendarEvent(body: { start_date: Date, end_date: Date, type: EventType, reminder?: number[], system_cal_id?: string }): Promise<CalendarEvent> {
        let apiReqest = new AuthenticatedRequest<CalendarEvent>('/calendar/event', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            ...body
        });
        return apiReqest.send(CalendarEvent).then((response) => {
            return <CalendarEvent>response.data
        })
    }

    /**
     * 
     * @param id Id of the Calendar Event
     * @param body BodyParameters
     */
    public static updateCalendarEvent(id: string, body: { start_date?: Date, end_date?: Date, type?: EventType, reminder?: number[], system_cal_id?: string}): Promise<CalendarEvent> {
        let apiReqest = new AuthenticatedRequest<CalendarEvent>(`/calendar/event/${id}`, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            ...body
        });
        return apiReqest.send(CalendarEvent).then((response) => {
            return <CalendarEvent>response.data
        })
    }

    /**
     * 
     * @param id Id of the Calendar Event
     */
    public static deleteCalendarEvent(id: string): Promise<any> {
        let apiReqest = new AuthenticatedRequest<any>(`/calendar/event/${id}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

}