import { JsonProperty } from "../requests/jsonparser";
import { BaseModel } from "./base";

export class Company extends BaseModel {

    @JsonProperty({ type: 'string' })
    email: string;

    @JsonProperty({ type: 'string' })
    name: string;

    @JsonProperty({ type: 'string' })
    website: string;

    @JsonProperty({ type: 'string' })
    contact: string;

    @JsonProperty({ type: 'string' })
    phone_number: string;

    @JsonProperty({ type: 'string?' })
    img_url?: string;

    @JsonProperty({ type: 'string?' })
    img_thumbnail_url?: string;

    @JsonProperty({ type: 'string' })
    invite_key: string;

    @JsonProperty({ type: 'string' })
    live_eligible: string;
}