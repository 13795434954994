import { Discount } from "../models/discount";
import { APIVersion, AuthenticatedRequest, HTTPMethod } from "../requests/request"

export class API {

    /**
     * Get the discounts
     */
    public static getDiscounts(): Promise<Discount[]> {
        let apiReqest = new AuthenticatedRequest<Discount>('/discounts', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send(Discount).then((response) => {
            return <Discount[]>response.data
        })
    }
}