import { BaseModel } from "../models/base";
import { Points, PointsType } from "../models/points";
import { APIVersion, AuthenticatedRequest, HTTPMethod } from "../requests/request";

export type CountType = 'month' | 'today' | 'week'

type CreatePoint = Omit<Points, keyof BaseModel>

export class API {

    /**
     * Get the points of user
     * 
     * @param range CountType
     */
    public static countPoints(range?: CountType, type?: PointsType): Promise<number> {
        let apiReqest = new AuthenticatedRequest<number>('/points/count', HTTPMethod.GET, APIVersion.v2_0, {
            range,
            type
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * 
     * @param range 
     * @param type 
     */
    public static getPoints(range?: CountType, subtract?: number, type?: PointsType): Promise<Points[]> {
        let apiReqest = new AuthenticatedRequest<Points>('/points', HTTPMethod.GET, APIVersion.v2_0, {
            range,
            subtract,
            type
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Add points to an users Account
     * 
     * @param count 
     * @param type 
     */
    public static addPoints(count: number, type?: PointsType, training_id?: string): Promise<Points> {
        training_id ? training_id=training_id : training_id="noID"
        let apiReqest = new AuthenticatedRequest<Points>('/points', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            count,
            type,
            training_id
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    public static addPointsBatch(points: CreatePoint[]): Promise<Points[]> {
        let apiReqest = new AuthenticatedRequest<Points>('/points/batch', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            batch: points
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    public static getLastPointSync(type?: PointsType): Promise<{ date: string }> {
        let apiReqest = new AuthenticatedRequest<Points>('/points/last-sync', HTTPMethod.GET, APIVersion.v1_0, {
            type
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }
}