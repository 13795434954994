import { JsonProperty } from "../requests/jsonparser";
import { BaseModel } from "./base";

export enum PointsType {
    TrainingBusiness = 'training_business',
    TrainingHome = 'training_home',
    Meditation = 'meditation',
    Steps = 'steps'
}

export class UserChallengeAssociation extends BaseModel {

    @JsonProperty({ type: 'boolean?' })
    share_points?: boolean;

    @JsonProperty({ type: 'boolean?' })
    anonym?: boolean;

    @JsonProperty({ type: 'string' })
    user_id: string;

    @JsonProperty({ type: 'string' })
    challenge_id: string;
}

export type ChallengeGet = UserChallengeAssociation & {
    UserChallengeAssociation: {
        id: string
    }
}

export class Challenge extends BaseModel {

    @JsonProperty({ type: 'string' })
    name: string;

    @JsonProperty({ type: 'string' })
    description: string;

    @JsonProperty({ type: 'number?' })
    target?: number;

    @JsonProperty({ type: 'Date?' })
    start_date?: Date

    @JsonProperty({ type: 'Date?' })
    end_date?: Date

    @JsonProperty({ type: 'number?' })
    prize?: number;

    @JsonProperty({ type: 'PointsType' })
    points_type: PointsType;

    @JsonProperty({ type: 'boolean' })
    is_fixed: boolean;

    @JsonProperty({ clazz: UserChallengeAssociation, type: 'UserChallengeAssociation' })
    UserChallengeAssociation?: UserChallengeAssociation;

}

export class Ranking extends BaseModel {

    @JsonProperty({ type: 'string?' })
    name?: string;

    @JsonProperty({ type: 'boolean' })
    isOwn: boolean;

    @JsonProperty({ type: 'number' })
    points: number;
}