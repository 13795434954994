import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import { Training } from "../sdk/api/models/training";
import { Translate } from "../sdk/translate";
import { API as TrainingAPI } from '../sdk/api/endpoints/training';
import { API as PointsAPI } from '../sdk/api/endpoints/points';
import { BusinessTrainingType, FavBusinessTraining } from "../sdk/api/models/favBusinessTraining";
import { FitjoLoader } from "../components/FitjoLoader";
import GenericPopup from "../components/GenericPopup";
import VideoPlayer from "../components/VideoPlayer";
import { Input } from "antd";
import { messageType } from "../sdk/utils/types";

// Assets
import { ARROW_LEFT } from "../assets/exports";

type Props = {};
type SingleTrainingProps = Props & RouteComponentProps<{
    type: BusinessTrainingType
}>;

const SingleTraining = (props: SingleTrainingProps) => {
    const history = useHistory()
    const [points, setPoints] = useState<number>()
    const [message, setMessage] = useState<[string, messageType]>(['', 'info'])
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingAddFav, setLoadingAddFav] = useState<boolean>(false)
    const [loadingAddPoints, setLoadingAddPoints] = useState<boolean>(false)
    const params = useParams<{ type, id }>()
    const [headline, setHeadline] = useState<string>()
    const [training, setTraining] = useState<Array<Training>>([])
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [showFavPopup, setShowFavPopup] = useState<boolean>(false)
    const [count, setCount] = useState<number>(0);
    const [favPopupState, setFavPopupState] = useState<boolean>(false)
    const [trainingName, setTrainingName] = useState<string>('')

    useEffect(() => {
        switch (params.type as BusinessTrainingType) {
            case 'business_standing':
                setHeadline(Translate.Standing)
                break
            case 'business_sedentary_desk':
                setHeadline(Translate.SedentaryDesk)
                break
            case 'business_sedentary_car':
                setHeadline(Translate.SedentaryCar)
                break
        }

        PointsAPI.countPoints('today', 'training_business').then((points) => {
            setPoints(points)
        })

        if (!params.id)
            getRandomTraining()
        else {
            reconstructTraining()
        }
    }, [])

    const getRandomTraining = () => {
        setLoading(true)
        TrainingAPI.getTraining(params.type, true, 7).then((trainingArr) => {
            if (trainingArr.length !== 0) {
                setTraining(trainingArr)
                setCurrentIndex(0)
            }
            else {
                setMessage([Translate.NoTrainings, 'error'])
            }
        }).catch((err) => {
            console.log('Error', err)
            setMessage([Translate.TrainingError, 'error'])
        }).finally(() => {
            setLoading(false);
        })
    }

    const reconstructTraining = () => {
        setLoading(true)
        TrainingAPI.getFavoriteBusinessTraining().then((trainingArr) => {
            if (trainingArr.length !== 0) {
                trainingArr.every((favBusTraining) => {
                    if (favBusTraining.id === params.id) {
                        setTraining(favBusTraining.trainings)
                        setCurrentIndex(0)
                    }
                })
            }
            else {
                setMessage([Translate.NoTrainings, 'error'])
            }
        }).catch((err) => {
            console.log('Error', err)
            setMessage([Translate.TrainingError, 'error'])
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleVideoEnded = (ref: React.RefObject<HTMLVideoElement>) => {
        if (currentIndex + 1 < training.length) {
            setCurrentIndex(currentIndex + 1)
            ref.current?.play()
        }
        else {
            if (!points) {
                let countArr = training ? training.map((val) => val.points) : []
                let count = countArr.length > 0 ? countArr.reduce((a, b) => a + b) : 0
                setCount(count);
                setLoadingAddPoints(true)
                setShowPopup(true)

                PointsAPI.addPoints(count, 'training_business', training[0].id).then(() => {
                }).catch((err) => {
                    console.log('Error', err)
                    setMessage([Translate.AddPointsError, 'error'])
                }).finally(() => {
                    setLoadingAddPoints(false)
                })
            }
            else {
                setShowFavPopup(true)
            }
        }
    }

    const switchPopups = () => {
        setShowPopup(false)
        setShowFavPopup(true)
    }

    const handleNo = () => {
        setShowFavPopup(false)
        setFavPopupState(false)
        setTrainingName('')
        getRandomTraining()
    }

    const handleSubmit = () => {
        if (!favPopupState) {
            setFavPopupState(true)
        }
        else {
            setLoadingAddFav(true)
            TrainingAPI.addFavoriteBusinessTraining(trainingName, params.type, training!.map((val) => val.id!)).then(() => {
                setMessage([Translate.SavedUnder('training'), 'success'])
            }).catch((err) => {
                console.log(err)
                setMessage([Translate.GeneralError, 'error'])
            }).finally(() => {
                setLoadingAddFav(false)
                setTimeout(() => {
                    setShowFavPopup(false)
                    setFavPopupState(false)
                    setTrainingName('')
                    getRandomTraining()
                }, 1000)
            })
        }
    }

    console.log(training);

    return (
        <>
            {
                !loading ?
                    <div className="single-training-container">
                        <div className="header">
                            <>{headline}</>
                            <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                        </div>
                        <div className="training-today">{Translate.TrainingToday}</div>
                        <div className="training-info">{Translate.TrainingInfo}</div>
                        <div className="training-info">{points ? Translate.TrainingInfoPointsAlreadyCollected : Translate.TrainingInfoPoints}</div>
                        <div className="video-container">
                            <VideoPlayer thumbnail={training[currentIndex].video_thumbnail} url={training[currentIndex].video_url} onVideoEnd={handleVideoEnded} />
                        </div>

                        <GenericPopup show={message[0] !== ''} message={message} setMessageState={setMessage} />
                        <div style={{ display: showPopup ? 'flex' : 'none' }} className="popup-page">
                            <div className="popup-container">
                                <div className="top">
                                    {
                                        !loadingAddPoints ?
                                            <>
                                                <div>{Translate.Super}!</div>
                                                <div className="popup-text">{Translate.FeedbackWorkoutText(count)}</div>
                                            </>
                                            :
                                            <FitjoLoader />
                                    }
                                </div>
                                <div className="bottom">
                                    <div onClick={switchPopups} className="button">{Translate.Ok}</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: showFavPopup ? 'flex' : 'none' }} className="popup-page">
                            <div className="popup-container">
                                <div className="top">
                                    {
                                        !loadingAddFav ?
                                            <>
                                                <div>{Translate.SaveWorkout}?</div>
                                                <div className="popup-text">{!favPopupState ? Translate.SaveWorkoutText : Translate.SaveWorkoutName}</div>
                                                <Input style={{ display: favPopupState ? 'block' : 'none' }} className="input" value={trainingName} onChange={(event) => setTrainingName(event.target.value)} placeholder={Translate.Name} />
                                            </>
                                            :
                                            <FitjoLoader />
                                    }
                                </div>
                                <div className="bottom">
                                    <div onClick={handleNo} className="button">{Translate.NoThanks}</div>
                                    <div onClick={handleSubmit} className="button">{Translate.SaveWorkout}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="loading-page">
                        <div className="loading-container">
                            <FitjoLoader />
                        </div>
                    </div>
            }

        </>
    )
}

export default SingleTraining;