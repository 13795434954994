import { Challenge, ChallengeGet, Ranking, UserChallengeAssociation } from "../models/challenge";
import { APIVersion, AuthenticatedRequest, HTTPMethod } from "../requests/request";


export class API {

    /**
     * Returns the available challenges of a user
     */
    public static getChallenges(): Promise<Challenge[]> {
        let apiReqest = new AuthenticatedRequest<Challenge>('/challenges', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send(Challenge).then((response) => {
            return <Challenge[]>response.data
        })
    }

    /**
     * Returns the challenges in which a user participates.
     */
    public static getOwnChallenges(): Promise<ChallengeGet[]> {
        let apiReqest = new AuthenticatedRequest<ChallengeGet>('/challenges/me', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return <ChallengeGet[]>response.data
        })
    }

    /**
     * Returns the Ranking of a challenge
     * 
     * @param challengeId 
     */
    public static getRanking(challengeId: string): Promise<Ranking[]> {
        let apiReqest = new AuthenticatedRequest<Ranking>(`/challenges/${challengeId}/ranking`, HTTPMethod.GET, APIVersion.v2_0);
        return apiReqest.send(Ranking).then((response) => {
            return <Ranking[]>response.data
        })
    }

    /**
     * Create a Challenge Participation
     * 
     * @param challengeId 
     * @param anonym 
     * @param share_points 
     */
    public static createChallengeParticipation(challengeId: string, anonym?: boolean, share_points?: boolean): Promise<UserChallengeAssociation> {
        let apiReqest = new AuthenticatedRequest<UserChallengeAssociation>(`/challenges/participation/${challengeId}`, HTTPMethod.POST, APIVersion.v1_0, undefined, {
            anonym,
            share_points
        });
        return apiReqest.send().then((response) => {
            return <UserChallengeAssociation>response.data
        })
    }

    public static updateChallengeParticipation(userChallengeAssociationId: string, anonym?: boolean, share_points?: boolean): Promise<UserChallengeAssociation> {
        let apiReqest = new AuthenticatedRequest<UserChallengeAssociation>(`/challenges/participation/${userChallengeAssociationId}`, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            anonym,
            share_points
        });
        return apiReqest.send(UserChallengeAssociation).then((response) => {
            return <UserChallengeAssociation>response.data
        })
    }

    public static deleteChallengeParticipation(userChallengeAssociationId: string): Promise<any> {
        let apiReqest = new AuthenticatedRequest<any>(`/challenges/participation/${userChallengeAssociationId}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }
}