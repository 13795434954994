import { BusinessTrainingType, FavBusinessTraining } from "../models/favBusinessTraining";
import { Training, TrainingType } from "../models/training";
import { APIVersion, AuthenticatedRequest, HTTPMethod } from "../requests/request";

export class API {

    /**
     * Get Trainings
     * 
     * @param type TrainingType
     * @param random 
     * @param limit
     */
    public static getTraining(type?: TrainingType, random?: boolean, limit?: number): Promise<Training[]> {
        let apiReqest = new AuthenticatedRequest<Training>('/training', HTTPMethod.GET, APIVersion.v1_0, {
            type,
            random,
            limit
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Returns the favorite Business Trainings
     */
    public static getFavoriteBusinessTraining(type?: BusinessTrainingType): Promise<FavBusinessTraining[]> {
        let apiReqest = new AuthenticatedRequest<FavBusinessTraining>('/training/favorites/business', HTTPMethod.GET, APIVersion.v1_0, {
            type
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Add a business Training to favorites
     * 
     * @param name 
     * @param trainings 
     */
    public static addFavoriteBusinessTraining(name: string, type: BusinessTrainingType, trainings: string[]): Promise<FavBusinessTraining> {
        let apiReqest = new AuthenticatedRequest<FavBusinessTraining>('/training/favorites/business/add', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            name,
            type,
            trainings
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Delete a favorite Business Training
     * 
     * @param id 
     */
    public static deleteFavoriteBusinessTraining(id: string): Promise<{}> {
        let apiReqest = new AuthenticatedRequest<{}>(`/training/favorites/business/${id}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }
}