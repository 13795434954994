import { useEffect, useState } from "react";
import { messageType } from "../sdk/utils/types";

interface GenericPopupProps {
    show: boolean
    message: [string, messageType]
    setMessageState: (message: [string, messageType]) => void
}

/* 
    show={message[0] !== 0}
    message: Message to display, Type determines color
    setMessageState: Pass setMessage from parent to reset (and hide) popup
*/
const GenericPopup = (props: GenericPopupProps) => {
    const [color, setColor] = useState<string>()

    useEffect(() => {
        if (props.message[0] !== '') {
            switch (props.message[1]) {
                case 'success':
                    setColor('#9fc1a3')
                    break
                case 'info':
                    setColor('#fdfd96')
                    break
                case 'error':
                    setColor('#ff3e52')
                    break
            }

            props.setMessageState([props.message[0], props.message[1]])
            setTimeout(() => {
                props.setMessageState(['', props.message[1]])
            }, 1500)
        }
    }, [props.show])

    return (
        <>
            <div style={{ top: props.message[0] !== '' ? '0' : '-100%' }} className="window-overlay">
                <div style={{ backgroundColor: color }} className="generic-popup-container">
                    {props.message[0]}
                </div>
            </div>
        </>
    )
}

export default GenericPopup;