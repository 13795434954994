import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import { Challenge, ChallengeGet, Ranking, UserChallengeAssociation } from "../sdk/api/models/challenge";
import { Translate } from "../sdk/translate";
import { API as ChallengeAPI } from '../sdk/api/endpoints/challenges';

// Assets
import {
    ARROW_LEFT,
    DISCOUNTS_IMAGE_1,
} from "../assets/exports";
import { FitjoLoader } from "../components/FitjoLoader";
import { Button } from "antd";

type Props = {
    challenges: Array<Challenge>
};
type ChallengeSingleProps = Props & RouteComponentProps<{}>;

const ChallengeSingle = (props: ChallengeSingleProps) => {
    const history = useHistory();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const params = useParams<{ id }>()
    const [challenge, setChallenge] = useState<Challenge>()
    const [challengeAssociation, setChallengeAssociation] = useState<string>('')
    const [participates, setPartcipates] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [rankings, setRankings] = useState<Array<Ranking>>([])
    const [loadRankings, setloadRankings] = useState<boolean>(true)
    const [loadingUpdateParticipation, setLoadingUpdateParticipation] = useState(false)

    useEffect(() => {
        props.challenges.every((singleChallenge) => {
            if (singleChallenge.id === params.id) {
                setChallenge(singleChallenge)
                setLoading(false)
                ChallengeAPI.getRanking(singleChallenge.id!).then((rankingsArr) => {
                    setRankings(rankingsArr)
                }).catch((err) => {
                    console.log("Error", err)
                }).finally(() => {
                    setloadRankings(false)
                })
                return false
            }
            return true
        })

        ChallengeAPI.getOwnChallenges().then((userChallengeAssociations) => {
            userChallengeAssociations.every((association) => {
                if (association.id === params.id) {
                    setChallengeAssociation(association.UserChallengeAssociation.id)
                    setPartcipates(true)
                    return false
                }
                return true
            })
        })
    }, [props.challenges, params.id, participates])

    const handleYes = () => {
        setLoadingUpdateParticipation(true)
        if (participates) {
            ChallengeAPI.deleteChallengeParticipation(challengeAssociation).then(() => {
                setPartcipates(false)
                setShowPopup(false)
            }).catch((err) => {
                console.log('Error', err)
            }).finally(() => {
                setLoadingUpdateParticipation(false)
            })
        }
        else {
            ChallengeAPI.createChallengeParticipation(challenge!.id!, true).then((created) => {
                setChallengeAssociation(created.id!)
                setPartcipates(true)
                setShowPopup(false)
            }).catch((err) => {
                console.log('Error', err)
            }).finally(() => {
                setLoadingUpdateParticipation(false)
            })
        }
    }

    const handleNo = () => {
        if (participates) {
            setShowPopup(false)
        }
        else {
            setLoadingUpdateParticipation(true)
            ChallengeAPI.createChallengeParticipation(challenge!.id!, false).then((created) => {
                setChallengeAssociation(created.id!)
                setPartcipates(true)
                setShowPopup(false)
            }).catch((err) => {
                console.log('Error', err)
            }).finally(() => {
                setLoadingUpdateParticipation(false)
            })
        }
    }

    const comparePoints = (a: Ranking, b: Ranking) => {
        if (a.points < b.points)
            return 1
        else if (b.points < a.points)
            return -1
        return 0
    }
    
    useEffect(() => {
        console.log(rankings)
    }, [rankings])

    return (
        <>
            {
                !loading ?
                    <div className="challenge-single-container">
                        <img className="image" src={DISCOUNTS_IMAGE_1} alt="" />
                        <div className="content-container">
                            <div className="header">
                                <>{challenge?.name}</>
                                <img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
                            </div>
                            <div className="text">{challenge?.description}</div>
                            <div onClick={() => setShowPopup(true)} className="button">{!participates ? Translate.Participate : Translate.EndParticipation}</div>
                            {
                                participates ?
                                    (!loadRankings ?
                                        (rankings.length !== 0 ?
                                            <div className="rankings">
                                                <div className="headline">{Translate.Ranking}</div>
                                                {
                                                    rankings.sort(comparePoints).map((ranking, key) => {
                                                        return (
                                                            <div key={key} className={ranking.isOwn ? 'rank active' : 'rank'}>{key + 1}. {ranking.name ? ranking.name : Translate.Anonym} | {ranking.points} {Translate.Points}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            <div className='rank'>Keine Teilnehmer gefunden</div>
                                        )
                                        :
                                        <div className="loader-container">
                                            <FitjoLoader />
                                        </div>
                                    )
                                    :
                                    <>
                                    </>
                            }
                        </div>
                        <div style={{ display: showPopup ? "block" : "none" }} className="popup-container">
                            <div className="top">
                                <div className="header">{Translate.Participation}</div>
                                <div className="question">{!participates ? Translate.ParticipateAnonym : Translate.EndParticipationQuestion}</div>
                            </div>
                            <div className="bottom">
                                <Button loading={loadingUpdateParticipation} onClick={() => handleNo()} className="button">{Translate.No}</Button>
                                <Button loading={loadingUpdateParticipation} onClick={() => handleYes()} className="button">{Translate.Yes}</Button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="loading-page">
                        <div className="loader-container">
                            <FitjoLoader />
                        </div>
                    </div>
            }
        </>
    );
}

export default ChallengeSingle;