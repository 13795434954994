import { AuthenticatedRequest } from './../requests/request';
import { User } from "../models/user";
import { APIVersion, AuthenticatedFileRequest, HTTPMethod } from "../requests/request";
import { Company } from '../models/company';

export class API {

    /**
     * Upload a profile Picture
     * 
     * @param image Image file
     */
    public static uploadProfileImage(image: any): Promise<User> {
        let apiReqest = new AuthenticatedFileRequest<User>('/users/me/picture', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            image
        });
        return apiReqest.send(User).then((response) => {
            return response.data
        })
    }

    public static updateProfile(email?: string, full_name?: string, dateOfBirth?: Date, gender?: string): Promise<User> {
        let apiRequest = new AuthenticatedRequest<User>('/users/me', HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            email,
            full_name,
            dateOfBirth,
            gender
        })
        return apiRequest.send(User).then((response) => {
            return response.data
        })
    }

    /**
     * Get the user
     */
    public static getUser(): Promise<User> {
        let apiRequest = new AuthenticatedRequest<User>('/users/me', HTTPMethod.GET, APIVersion.v1_0)
        return apiRequest.send(User).then((response) => {
            return response.data
        })
    }

    /**
     * Get own Company
     */
    public static getCompany(): Promise<Company> {
        let apiRequest = new AuthenticatedRequest<Company>('/users/company', HTTPMethod.GET, APIVersion.v1_0)
        return apiRequest.send(Company).then((response) => {
            return response.data
        })
    }

    /**
     * Delete a user account
     */
    public static deleteAccount(): Promise<string> {
        let apiRequest = new AuthenticatedRequest<string>('/users/me', HTTPMethod.DELETE, APIVersion.v1_0)
        return apiRequest.send().then((response) => {
            return response.data
        })
    }

    public static resetPassword(token: string, password: string): Promise<User> {
        let apiRequest = new AuthenticatedRequest<User>('/resetpassword', HTTPMethod.POST, APIVersion.v1_0, {}, { code: token, password })
        return apiRequest.send(User).then((response) => {
            return response.data
        })
    }


    public static forgotPassword(email: string): Promise<void> {
        let apiRequest = new AuthenticatedRequest<User>('/forgotpassword', HTTPMethod.POST, APIVersion.v1_0, {}, { email })
        return apiRequest.send().then((response) => {
            return response.data
        })
    }

}