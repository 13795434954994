import { useState } from "react";
import { Switch, Route, Redirect } from "react-router";
import { NavItems } from "./models/nav-items";
import { Translate } from "./sdk/translate";

// Components
import Header from "./components/header";
import Sidebar from "./components/Sidebar";
import { SidebarContextProvider } from "./context/sidebar";

// Pages
import Survey from "./pages/Survey";
import Business from "./pages/Business";
import Home from "./pages/Home";
import MyCompany from "./pages/MyCompany";
import Steps from "./pages/Steps";
import Meditation from "./pages/Meditation";
import Contact from "./pages/Contact";
import Events from "./pages/Events";
import News from "./pages/News";
import NewsCategory from "./pages/NewsCategory";
import Favorites from "./pages/Favorites";
import FavoritesCategory from "./pages/FavoritesCategory";
import Fitjo from "./pages/Fitjo";
import FitjoInfo from "./pages/FitjoInfo";
import Discounts from "./pages/Discounts";
import Profil from "./pages/Profil";
import Account from "./pages/Account";
import Challenge from "./pages/Challenge";
import Calendar from "./pages/Calendar";
import Nutrition from "./pages/Nutrition";
import SingleTraining from "./pages/SingleTraining";
import HomeSingle from "./pages/HomeSingle";

const Authorized = () => {
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

    return (
        <>
            <SidebarContextProvider value={{ open: sidebarOpen }}>
                <Header
                    navItems={navItems}
                    onMenuClick={() => setSidebarOpen(!sidebarOpen)}
                    sidebarOpen={sidebarOpen}
                />
                <Sidebar onCloseClick={() => setSidebarOpen(!sidebarOpen)} navItems={navItems} />
                <Switch>
                    <Route path="/survey/business/:type/:id" component={SingleTraining} />
                    <Route path="/survey/business/:type/" component={SingleTraining} />
                    <Route path="/survey/business" component={Business} />
                    <Route path="/survey/home/:category" component={HomeSingle} />
                    <Route path="/survey/home" component={Home} />
                    {/* <Route path="/survey/steps" component={Steps} /> */}
                    <Route path="/survey/nutrition/:type" component={Nutrition} />
                    <Route path="/survey/meditation" component={Meditation} />
                    <Route path="/my-company/contact" component={Contact} />
                    <Route path="/my-company/events" component={Events} />
                    <Route path="/survey" component={Survey} />
                    <Route path="/my-company" component={MyCompany} />
                    <Route path="/news/:category" component={NewsCategory} />
                    <Route path="/news" component={News} />
                    <Route path="/favorites/:category" component={FavoritesCategory} />
                    <Route path="/favorites" component={Favorites} />
                    <Route path="/fitjo/info" component={FitjoInfo} />
                    <Route path="/fitjo/discounts" component={Discounts} />
                    <Route path="/fitjo" component={Fitjo} />
                    <Route path="/profile/challenge" component={Challenge} />
                    <Route path="/profile/account" component={Account} />
                    <Route path="/profile/calendar" component={Calendar} />
                    <Route path="/profile" component={Profil} />
                    <Route render={() => {
                        return <Redirect to={'/survey'} />
                    }} />
                </Switch>
            </SidebarContextProvider>
        </>
    );
}

export default Authorized;

const navItems: Array<NavItems> = [
    {
        page: Translate.Survey,
        url: "/survey"
    }, {
        page: Translate.MyCompany,
        url: "/my-company"
    }, {
        page: Translate.News,
        url: "/news"
    }, {
        page: Translate.Favorites,
        url: "/favorites"
    }, {
        page: "FITJO",
        url: "/fitjo"
    }
]
