import { Route, RouteComponentProps, Switch, useHistory, useParams } from "react-router";
import DropdownBox from "../components/DropdownBox";
import { Training, TrainingType } from "../sdk/api/models/training";
import { Translate } from "../sdk/translate";
import React, { useEffect, useState } from "react";
import { API as TrainingAPI } from '../sdk/api/endpoints/training';

// Assets
import { ARROW_LEFT, WORKOUT1 } from "../assets/exports";
import { messageType } from "../sdk/utils/types";
import { FitjoLoader } from "../components/FitjoLoader";
import GenericPopup from "../components/GenericPopup";
import Video from "./Video";

type Props = {
	videos: Array<Training>
};
type HomeSingleProps = Props & RouteComponentProps<{}>;

const HomeSingle = (props: HomeSingleProps) => {
	const params = useParams<{ category }>()
	const [loading, setLoading] = useState<boolean>(true)
	const [headline, setHeadline] = useState<string>('')
	const history = useHistory()
	const [videos, setVideos] = useState<Array<Training>>([])
	const [message, setMessage] = useState<[string, messageType]>(['', 'info'])

	useEffect(() => {
		let type = '' as TrainingType
		switch (params.category) {
			case 'wholebody':
				setHeadline(Translate.WholeBodyTraining)
				type = 'home_whole_body'
				break
			case 'backfit':
				setHeadline(Translate.Backfit)
				type = 'home_backfit'
				break
			case 'mobility':
				setHeadline(Translate.Mobility)
				type = 'home_mobility'
				break
			case 'hiit':
				setHeadline(Translate.Hiit)
				type = 'home_hiit'
				break
			case 'alb':
				setHeadline(Translate.ALB)
				type = 'home_alb'
				break
		}

		TrainingAPI.getTraining(type).then((trainingArr) => {
			if (trainingArr.length !== 0) {
				setVideos(trainingArr)
			}
		}).catch((err) => {
			console.log('Error', err)
			setMessage([Translate.NoTrainings, 'error'])
		}).finally(() => {
			setLoading(false)
		})
	}, [params])

	return (
		<>
			<Switch>
				<Route path='/survey/home/:category/:id' component={() => <Video {...props} videos={videos} />} />
				<Route render={() => {
					return (
						<div className="home-single-container">
							{
								!loading ?
									<>
										<div className="header">
											<>{headline}</>
											<img onClick={() => history.goBack()} className="arrow-left" src={ARROW_LEFT} alt="" />
										</div>
										<div className="row">
											{
												videos.length > 0 ?
													videos.map((video, key) => {
														return (
															<React.Fragment key={key}>
																<DropdownBox singleLink={"/survey/home/" + params.category + "/" + video.id} image={video.video_thumbnail ? video.video_thumbnail : WORKOUT1} header={video.name} text={Translate.Length + ": " + video.points + " " + Translate.Minutes + " | " + Translate.Points + ": " + video.points} buttonText={Translate.StartWorkout} />
															</React.Fragment>
														)
													})
													:
													<div className="element">{Translate.NoTrainings}</div>
											}
										</div>
									</>
									:
									<div className="loading-page">
										<div className="loader-container">
											<FitjoLoader />
										</div>
									</div>
							}
							<GenericPopup show={message[0] !== ''} message={message} setMessageState={setMessage} />
						</div>
					)
				}} />
			</Switch>
		</>
	)
}

export default HomeSingle;